import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {getPlayback} from 'api/measurements';
import {useSnackbar} from 'notistack';
import {withAuthorization} from 'oauth/Session';
import {setDeployment, setFrameRate, setPeriod} from 'redux/reports.reducer';
import * as Conditions from 'routes/Conditions';

import GraphicalReport from './components/GraphicalReport';
import ReportForm from './components/ReportForm';

const PlaybackPage = () => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const deploymentId = useSelector((state) => state.reports.deploymentId);

  const [isDownloading, setIsDownloading] = useState(false);
  const [polygons, setPolygons] = useState([]);

  const getReportData = (payload) => {
    setIsDownloading(true);
    getPlayback(payload)
      .then((data) => {
        if (!data.data) {
          setPolygons([]);
          enqueueSnackbar('No report data available for the selected parameters', {variant: 'error'});
          return;
        }
        data.data.forEach((t) => (t.observationTime = +(Date.parse(t.observationTime).toString().slice(0, -2) + '00')));
        data.data.sort((a, b) => (a.observationTime < b.observationTime ? -1 : 1));
        dispatch(setDeployment(payload.deploymentId));
        dispatch(setFrameRate(100));
        dispatch(setPeriod([Date.parse(payload.from), Date.parse(payload.to)]));
        setPolygons(data.data);
      })
      .catch((error) => {
        setPolygons([]);
        enqueueSnackbar(`Error getting report data: ${error.message}`, {variant: 'error'});
      })
      .finally(() => setIsDownloading(false));
  };

  useEffect(() => setPolygons([]), [deploymentId]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Playback</Typography>
        </Grid>
        <ReportForm getReportData={getReportData} isDownloading={isDownloading} />
        {deploymentId !== 0 && (
          <Grid item xs={12}>
            <GraphicalReport polygons={polygons} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withAuthorization(Conditions.ANY)(PlaybackPage);
