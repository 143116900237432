import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CircularProgress, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {getFlowJSON} from 'api/measurements';
import {getSensors} from 'api/sensors';
import {refreshCameras, refreshDeployments} from 'helpers';
import _ from 'lodash';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {deleteDashboardKey, setDashboardKeys, setDashboardSettings} from 'redux/dashboard.reducer';
import {setSensorsList} from 'redux/sensors.reducer';

import ChartTypeSelector from './components/ChartTypeSelector';
import ChartWrapper from './components/ChartWrapper';
import DateSelector from './components/DateSelector';
import Legend from './components/Legend';
import Matrix from './components/Matrix';
import PeriodArrow from './components/PeriodArrow';
import ResolutionSelector from './components/ResolutionSelector';
import SourceSelector from './components/SourceSelector';
import ViewControls from './components/ViewControls';
import {createChartDataPoints, DEFAULTDASHBOARDSETTINGS, formatMainRequestPeriod, paperLayoutStyles} from './helpers';

const COLORRANGE = ['#3b89bf', '#ee8c2b'];

function Flow(props) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const scrollRef = useRef(null);
  const deploymentsList = useSelector((state) => state.deployments.deploymentsList);
  const camerasList = useSelector((state) => state.cameras.camerasList);
  const sensorsList = useSelector((state) => state.sensors.sensorsList);
  const dashboardSettings = useSelector((state) => state.dashboard.settings);
  const [sourceDropdownData, setSourceDropdownData] = useState([]);
  const [entries, setEntries] = useState([]);
  const [exits, setExits] = useState([]);
  const [flowData, setFlowData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);

  const handleDeleteData = useCallback(
    (k) => {
      dispatch(deleteDashboardKey(k));
      setChartData([...chartData.map(({[k]: _, ...item}) => item)]);
    },
    [chartData, dispatch]
  );

  const updateChart = useCallback(
    (selectedSettings) => {
      const periodName =
        (selectedSettings.selectedFlow.entry === null || selectedSettings.selectedFlow.entry === 'Total'
          ? 'All'
          : sensorsList.find((s) => s.sensorId === selectedSettings.selectedFlow.entry)?.description ||
            selectedSettings.selectedFlow.entry) +
        ' > ' +
        (selectedSettings.selectedFlow.exit === null || selectedSettings.selectedFlow.exit === 'Total'
          ? 'All'
          : sensorsList.find((s) => s.sensorId === selectedSettings.selectedFlow.exit)?.description ||
            selectedSettings.selectedFlow.exit) +
        (selectedSettings.selectedFlow.detectionClass === null
          ? ''
          : ' (' + selectedSettings.selectedFlow.detectionClass + ')');
      const filters = [
        (v) =>
          selectedSettings.selectedFlow.entry === null || selectedSettings.selectedFlow.entry === 'Total'
            ? true
            : v.entry.sensorId === selectedSettings.selectedFlow.entry,
        (v) =>
          selectedSettings.selectedFlow.exit === null || selectedSettings.selectedFlow.exit === 'Total'
            ? true
            : v.exit.sensorId === selectedSettings.selectedFlow.exit,
        (v) =>
          selectedSettings.selectedFlow.detectionClass === null
            ? true
            : v.class === selectedSettings.selectedFlow.detectionClass,
      ];
      const filteredData = flowData.filter((v) => filters.every((f) => f(v)));
      const period = formatMainRequestPeriod(selectedSettings.period, selectedSettings.view);
      const from = period[0];
      const to = period[1];
      const chartDataPoints = createChartDataPoints(
        Date.parse(from),
        Date.parse(to),
        selectedSettings.resolution * 1000
      );
      const chartData = chartDataPoints.map((chd) => ({
        start: chd.start,
        [periodName]: filteredData.filter(
          (fd) => fd.entry.timestamp >= chd.start && fd.entry.timestamp < chd.start + selectedSettings.resolution * 1000
        ).length,
      }));
      dispatch(setDashboardKeys([{name: periodName, time: [Date.parse(from), Date.parse(to)]}]));
      setChartData([...chartData]);
    },
    [dispatch, flowData, sensorsList]
  );

  const updateData = useCallback(
    (selectedSettings) => {
      if (selectedSettings.sourceId === '') {
        return;
      }
      setIsLoading(true);
      setChartData([]);
      setFlowData(null);
      const requestPeriod = formatMainRequestPeriod(selectedSettings.period, selectedSettings.view);
      const from = requestPeriod[0];
      const to = requestPeriod[1];
      const allFlowCounters = deploymentsList
        .find((d) => d.id === selectedSettings.sourceId)
        .sensors.filter((s) => s.type === 'FOOTFALL' && s.isFlow)
        .map((s) => s.sensorId);
      const allPayloads = allFlowCounters.map((c) => ({
        entry: {sensorId: c, direction: 'IN'},
        exits: [...allFlowCounters.map((exit) => ({sensorId: exit, direction: 'OUT'}))],
        from,
        to,
        entryTimeout: 300,
      }));
      const requestArray = allPayloads.map((p) => getFlowJSON(p));
      Promise.all(requestArray)
        .then((data) => {
          const filteredData = data.map((d) => d.data.filter((dataPoint) => dataPoint.matchType === 'MATCHED')).flat();
          filteredData.forEach((d) => {
            d.entry.timestamp = Date.parse(d.entry.timestamp);
            d.exit.timestamp = Date.parse(d.exit.timestamp);
          });
          setEntries(allFlowCounters);
          setExits(allFlowCounters);
          setFlowData(filteredData);
        })
        .catch((error) => enqueueSnackbar(`Error getting data: ${error.message}`, {variant: 'error'}))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [deploymentsList, enqueueSnackbar]
  );

  const updateSensorsList = useCallback(() => {
    return getSensors()
      .then((response) => {
        dispatch(setSensorsList(response.data));
        return response.data;
      })
      .catch((error) => {
        enqueueSnackbar(`Error getting sensors: ${error.message}`, {variant: 'error'});
      });
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    // update deployments and sensors on first load
    refreshDeployments(dispatch);
    refreshCameras(dispatch);
    updateSensorsList();
  }, [dispatch, updateSensorsList]);

  useEffect(
    () => {
      if (deploymentsList.length > 0 && !isLoading) {
        // set default settings
        const deploymentsWithFlowFootfalls = _.cloneDeep(
          deploymentsList.filter((d) => d.sensors.some((s) => s.type === 'FOOTFALL' && s.isFlow))
        );
        const deploymentsWithCameraName = deploymentsWithFlowFootfalls.map((deployment) => {
          const cameraName = camerasList.find((camera) => camera.id === deployment.cameraId)?.name;
          if (cameraName) {
            deployment.name = cameraName + ' - ' + deployment.name;
          }
          return deployment;
        });
        setSourceDropdownData(deploymentsWithCameraName);
        const defaultSettings = {
          ...DEFAULTDASHBOARDSETTINGS['flow'],
          sourceId: deploymentsWithFlowFootfalls[0]?.id || '',
          sourceType: '',
          counters: ['AVG'],
          period:
            DEFAULTDASHBOARDSETTINGS['flow'].view !== 'year'
              ? moment().subtract(1, DEFAULTDASHBOARDSETTINGS['flow'].view)
              : null,
        };
        dispatch(setDashboardSettings(defaultSettings));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deploymentsList]
  );

  useEffect(() => {
    if (deploymentsList.length > 0 && !isLoading && dashboardSettings.sourceId !== '' && !flowData) {
      updateData(dashboardSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardSettings.sourceId]);

  useEffect(() => {
    if (chartData.length > 0) {
      scrollRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [chartData]);

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        ...paperLayoutStyles,
      }}>
      <Grid container justifyContent="center" rowSpacing={3}>
        <Grid container item direction="row" rowSpacing={2}>
          <Grid item xs={12}>
            <SourceSelector
              dropdownData={sourceDropdownData}
              updateChart={updateData}
              isDisabled={isLoading || sourceDropdownData.length === 0}
            />
          </Grid>
          <Grid container item xs={6} sm={4} alignSelf="flex-end" sx={{minWidth: '265px'}}>
            <ViewControls updateChart={updateData} isDisabled={isLoading || sourceDropdownData.length === 0} />
          </Grid>
          <Grid container item direction="row" justifyContent="center" xs={6} sm={true} sx={{minWidth: '200px'}}>
            <PeriodArrow
              direction="back"
              updateChart={updateData}
              isDisabled={isLoading || sourceDropdownData.length === 0}
            />
            <DateSelector updateChart={updateData} isDisabled={isLoading || sourceDropdownData.length === 0} />
            <PeriodArrow
              direction="forward"
              updateChart={updateData}
              isDisabled={isLoading || sourceDropdownData.length === 0}
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          {isLoading ? (
            <CircularProgress style={{margin: '130px auto'}} />
          ) : flowData ? (
            <Matrix
              exits={exits}
              entries={entries}
              flowData={flowData}
              updateChart={updateChart}
              isShowingChart={chartData.length > 0}
            />
          ) : (
            <Typography style={{margin: '130px'}}>No data available</Typography>
          )}
        </Grid>
        {chartData.length > 0 && !isLoading && (
          <>
            <Grid item container justifyContent="flex-end">
              <ChartTypeSelector isDisabled={isLoading || sourceDropdownData.length === 0} />
              <ResolutionSelector updateChart={updateChart} isDisabled={isLoading || sourceDropdownData.length === 0} />
            </Grid>
            <Legend
              keys={dashboardSettings.keys}
              colorRange={COLORRANGE}
              view={dashboardSettings.view}
              handleDeleteData={handleDeleteData}
              reference={scrollRef}
            />
            <ChartWrapper
              chartData={chartData}
              chartPeriod={[chartData[0].start, chartData[chartData.length - 1].start]}
              view={dashboardSettings.view}
              colorRange={COLORRANGE}
              keys={dashboardSettings.keys}
              dashboardType="Flow"
            />
          </>
        )}
      </Grid>
    </Paper>
  );
}

export default Flow;
