import React, {useCallback, useMemo} from 'react';
import {Group} from '@visx/group';
import {Bar} from '@visx/shape';

const Bars = ({chartData, keys, innerHeight, innerWidth, xScale, yScale}) => {
  const getValue = useCallback((d) => d[keys[0].name], [keys]);

  const generatedBars = useMemo(() => {
    const spaceBetweenBars = 0;
    return chartData.map((d, i) => {
      const date = d.start;
      let barWidth;
      if (i === 0) {
        barWidth = xScale(chartData[1].start) - spaceBetweenBars;
      } else {
        barWidth = innerWidth / chartData.length - spaceBetweenBars;
      }
      let barHeight = 0;
      if (!getValue(d)) {
        barHeight = getValue(d) === 0 ? 1 : 0;
      } else {
        barHeight =
          yScale.domain()[1] >= 0
            ? innerHeight - (yScale(getValue(d)) ?? 0)
            : Math.abs(yScale(getValue(d)) - yScale(0));
      }
      const barX = xScale(d.start);
      const barY =
        yScale.domain()[1] >= 0 ? innerHeight - barHeight : getValue(d) > 0 ? yScale(getValue(d)) : yScale(0);
      return <Bar key={`bar-${date}`} x={barX} y={barY} width={barWidth} height={barHeight} fill="#3b89bf" />;
    });
  }, [chartData, getValue, innerHeight, innerWidth, xScale, yScale]);

  return <Group>{generatedBars}</Group>;
};

export default React.memo(Bars);
