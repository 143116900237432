import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import log from 'loglevel';

import ConfiguratorImage from '../../../components/Configurator/ConfiguratorImage';
import ConfiguratorMap, {modes} from '../../../components/Configurator/ConfiguratorMap';
import ConfiguratorToggles from '../../../components/Configurator/ConfiguratorToggles';
import {setPolygons, updatePolygon} from '../../../redux/configurator';

const colorValidator = (color) => {
  if (color && color.length === 7 && color.substr(0, 1) === '#') {
    return color;
  }
  return '#000000';
};

const SpatialConfig = ({
  editableConfig,
  updateFromObject,
  deploymentId,
  deviceId,
  sensorGroupUpdates,
  setSensorGroupUpdates,
}) => {
  const polygons = useSelector((state) => state.configurator.polygons);
  const dispatch = useDispatch();

  const [spatialKey, setSpatialKey] = useState('functions');
  const [spatialSubKey, setSpatialSubKey] = useState('footfalls');
  const [doSave, setDoSave] = useState(false);

  const isLine = spatialSubKey === 'footfalls';

  const [imageModeSettings, setImageModeSettings] = useState({
    mode: modes.IMAGE,
    overlayUrl: '/img/floorplan.jpg',
    viewport: {
      latitude: 0.5,
      longitude: 0.5,
      zoom: 8.5,
    },
    imageLoaded: false,

    clamp: true,
    minLatitude: 0,
    maxLatitude: 1,
    minLongitude: 0,
    maxLongitude: 1,

    minZoom: 8,
    maxZoom: 10,
  });

  useEffect(() => {
    if (imageModeSettings.imageLoaded) {
      // From configuration JSON to Redux
      const data = editableConfig[spatialKey][spatialSubKey];
      const heightFactor = imageModeSettings.heightFactor;
      const widthFactor = imageModeSettings.widthFactor;
      const reduxFormat = data
        ? data.map((item) => {
            const shared = {
              color: colorValidator(item.color),
              shapeType: isLine ? 'line' : 'polygon',
              coordinates: item.coordinates.map((c) => [c[0] * widthFactor, (1 - c[1]) * heightFactor]),
              isEditable: false,
              capacity: item.capacity,
              isFlow: item.isFlow,
            };

            if (spatialKey === 'functions') {
              return {
                ...shared,
                id: item.id,
                areaDescription: item.areaDescription,
              };
            }
            return {
              ...shared,
              id: item.name,
            };
          })
        : [];
      log.info('JSON to Redux', reduxFormat);
      dispatch(setPolygons(reduxFormat));
    }
  }, [spatialKey, spatialSubKey, editableConfig, dispatch, isLine, imageModeSettings]);

  const save = useCallback(
    (polygons) => {
      // From Redux to configuration JSON
      const heightFactor = imageModeSettings.heightFactor;
      const widthFactor = imageModeSettings.widthFactor;
      const newList = polygons.map((p) => {
        const shared = {
          color: colorValidator(p.color),
          coordinates: p.coordinates.map((c) => [c[0] / widthFactor, 1 - c[1] / heightFactor]),
          capacity: p.capacity,
          isFlow: p.isFlow,
        };
        if (spatialKey === 'functions') {
          return {
            ...shared,
            id: p.id,
            areaDescription: p.areaDescription,
          };
        }

        return {
          ...shared,
          name: p.id,
        };
      });
      const newConfig = {...editableConfig};
      newConfig[spatialKey][spatialSubKey] = newList;

      updateFromObject(newConfig);
    },
    [editableConfig, spatialKey, spatialSubKey, updateFromObject, imageModeSettings]
  );

  useEffect(() => {
    const t = setTimeout(() => {
      if (doSave) {
        save(polygons);
        setDoSave(false);
      }
    }, 200);

    return () => {
      clearTimeout(t);
    };
  }, [save, polygons, doSave]);

  const onCommit = useCallback(() => {
    setTimeout(() => {
      setDoSave(true);
    }, 200);
  }, []);

  // Local changes to store, only. Not committing.
  const onModifyPolygons = (event) => {
    dispatch(updatePolygon(event));
  };

  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    switch (newValue) {
      case 0:
        setSpatialKey('functions');
        setSpatialSubKey('footfalls');
        break;
      case 1:
        setSpatialKey('functions');
        setSpatialSubKey('areaDetect');
        break;
      case 2:
        setSpatialKey('masks');
        setSpatialSubKey('detectionMasks');
        break;
      case 3:
        setSpatialKey('masks');
        setSpatialSubKey('noNewTracksMasks');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Typography variant="h5">Spatial configuration</Typography>
      <ConfiguratorImage
        deploymentId={deploymentId}
        imageModeSettings={imageModeSettings}
        setImageModeSettings={setImageModeSettings}
      />
      {imageModeSettings.imageLoaded && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}} style={{marginTop: '20px', marginBottom: '10px'}}>
              <Tabs value={tabIndex} onChange={handleChange} aria-label="kinds of sensors">
                <Tab label="Footfalls" />
                <Tab label="Area Detect" />
                <Tab label="Detection Masks" />
                <Tab label="No New Tracks" />
              </Tabs>
            </Box>
            {imageModeSettings.imageLoaded && (
              <Box sx={{width: '100%', height: '70vh'}}>
                <ConfiguratorMap
                  polygons={polygons}
                  onModifyPolygons={onModifyPolygons}
                  {...imageModeSettings}></ConfiguratorMap>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <ConfiguratorToggles
              polygons={polygons}
              onCommit={onCommit}
              isLine={isLine}
              showAreaDescription={spatialKey === 'functions'}
              spatialSubKey={spatialSubKey}
              deviceId={deviceId}
              sensorGroupUpdates={sensorGroupUpdates}
              setSensorGroupUpdates={setSensorGroupUpdates}></ConfiguratorToggles>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SpatialConfig;
