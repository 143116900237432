import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {getJSONForGroup, getJSONForSensor} from 'api/measurements';
import {getSensorGroups, getSensors} from 'api/sensors';
import _ from 'lodash';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {deleteDashboardKey, setDashboardKeys, setDashboardSettings} from 'redux/dashboard.reducer';
import {setSensorGroupsList, setSensorsList} from 'redux/sensors.reducer';

import AdvancedSettings from './components/AdvancedSettings';
import ChartWrapper from './components/ChartWrapper';
import DateSelector from './components/DateSelector';
import DownloadButton from './components/DownloadButton';
import Legend from './components/Legend';
import PeriodArrow from './components/PeriodArrow';
import ResolutionSelector from './components/ResolutionSelector';
import SourceSelector from './components/SourceSelector';
import ViewControls from './components/ViewControls';
import {
  createChartDataPoints,
  DEFAULTDASHBOARDSETTINGS,
  formatComparisonRequestPeriod,
  formatMainRequestPeriod,
  formatPeriodName,
  generatePayload,
  paperLayoutStyles,
} from './helpers';

const COLORRANGE = [
  '#3b89bf', // theme blue
  '#ee8c2b', // theme orange
  '#e6194B', // crimson
  '#3cb44b', // salad green
  '#ffe119', // yellow
  '#911eb4', // dark purple
  '#42d4f4', // sky blue
  '#f032e6', // light magenta
  '#bfef45', // yellow-ish green
  '#fabed4', // cotton candy pink
  '#469990', // blue-green
  '#dcbeff', // mauve
  '#9A6324', // brown
  '#16f52a', // neon green
  '#800000', // maroon
  '#aaffc3', // mint green
  '#808000', // olive
  '#bd9886', // tan
  '#000075', // navy
  '#0000ff', // blue
  '#d66e7e', // candy pink
  '#437209', // sap green
  '#feacac', // light pink
  '#c1c7ff', // lavender blue
  '#fcd4ae', // light orange
  '#af50ae', // fuchsia
  '#50699a', // muted blue
  '#6fa659', // bud green
  '#21676c', // ming
  '#7c2859', // pansy purple
  '#b9b604', // acid green
  '#000000', // black
];

function Occupancy(props) {
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const dashboardSettings = useSelector((state) => state.dashboard.settings);
  const [sourceDropdownData, setSourceDropdownData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);

  const handleDeleteData = useCallback(
    (k) => {
      dispatch(deleteDashboardKey(k));
      setChartData([...chartData.map(({[k]: _, ...item}) => item)]);
    },
    [chartData, dispatch]
  );

  const getChartData = useCallback(
    (payloads, chartDataPoints, keys) => {
      setIsLoading(true);
      const requestArray = payloads.map((p) => (p.aggregationWindow ? getJSONForSensor(p) : getJSONForGroup(p)));
      Promise.all(requestArray)
        .then((data) =>
          // endpoints for getJSONForSensor and getJSONForGroup return different object shapes
          data.map((responseArray) =>
            responseArray.map((dataPoint) =>
              dataPoint.start
                ? {...dataPoint, start: Date.parse(dataPoint.start)}
                : {start: Date.parse(dataPoint.timestamp), value: dataPoint.presenceCount}
            )
          )
        )
        .then((data) => data.map((d) => _(d).groupBy('start').value()))
        .then((data) =>
          chartDataPoints.map((chdp) => {
            const periodValues = payloads.map((payload, i) => {
              const dataPoint = data[i][chdp.start + (Date.parse(payload.from) - chartDataPoints[0].start)];
              const periodName = keys.find(
                (k) =>
                  k.time[0] === Date.parse(payload.from) &&
                  (payload.sensorGroupId ? k.source.id === payload.sensorGroupId : k.source.id === payload.sensorIds[0])
              ).name;
              return {
                [periodName]: dataPoint ? dataPoint.reduce((sum, obj) => sum + obj.value, 0) : null,
              };
            });
            const periodValuesObject = Object.assign({}, ...periodValues);
            return {
              ...chdp,
              ...periodValuesObject,
            };
          })
        )
        .then((data) => data.sort((a, b) => a.start - b.start))
        .then((data) => {
          dispatch(setDashboardKeys(keys));
          setChartData(data);
        })
        .catch((error) => enqueueSnackbar(`Error getting data: ${error.message}`, {variant: 'error'}))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch, enqueueSnackbar]
  );

  const handleAddComparison = useCallback(
    (date, sourceId) => {
      const comparisonSource = sourceDropdownData.find((source) => source.id === sourceId);
      const requestPeriod = formatComparisonRequestPeriod(
        date,
        dashboardSettings.view,
        dashboardSettings.keys[0].time,
        !!dashboardSettings.period
      );
      const periodName =
        formatPeriodName(date, dashboardSettings.view, true) +
        ' (' +
        (comparisonSource.name || comparisonSource.id) +
        ')';
      const newKey = {
        period: date,
        name: periodName,
        time: [Date.parse(requestPeriod[0]), Date.parse(requestPeriod[1])],
        source: comparisonSource,
      };
      dashboardSettings.keys.push(newKey);
      const payload = generatePayload(dashboardSettings, newKey, requestPeriod);
      getChartData([payload], chartData, dashboardSettings.keys);
    },
    [sourceDropdownData, dashboardSettings, getChartData, chartData]
  );

  const updateChart = useCallback(
    (selectedSettings) => {
      const periodName = formatPeriodName(selectedSettings.period, selectedSettings.view, false);
      const requestPeriod = formatMainRequestPeriod(selectedSettings.period, selectedSettings.view);
      const mainSource = {
        id: selectedSettings.sourceId,
        type: selectedSettings.sourceType,
        capacity: sourceDropdownData.find((s) => s.id === selectedSettings.sourceId).capacity,
      };
      if (selectedSettings.keys.length === 0) {
        selectedSettings.keys = [
          {name: periodName, time: [Date.parse(requestPeriod[0]), Date.parse(requestPeriod[1])], source: mainSource},
        ];
      }
      // check if the main request period has changed and update it
      if (
        Date.parse(requestPeriod[0]) !== selectedSettings.keys[0].time[0] ||
        Date.parse(requestPeriod[1]) !== selectedSettings.keys[0].time[1]
      ) {
        selectedSettings.keys[0] = {
          ...selectedSettings.keys[0],
          time: [Date.parse(requestPeriod[0]), Date.parse(requestPeriod[1])],
          name: periodName,
        };
        if (selectedSettings.keys.length > 1) {
          // if a comparison has been selected, update comparison period to match main period
          selectedSettings.keys.forEach((key, i) => {
            if (i === 0) {
              return;
            }
            const comparisonRequestPeriod = formatComparisonRequestPeriod(
              key.period,
              selectedSettings.view,
              selectedSettings.keys[0].time,
              !!selectedSettings.period
            );
            key.time = [Date.parse(comparisonRequestPeriod[0]), Date.parse(comparisonRequestPeriod[1])];
          });
        }
      }
      const payloads = selectedSettings.keys.map((k, i) =>
        generatePayload(
          selectedSettings,
          k,
          selectedSettings.keys[i].time.map((t) => new Date(t).toISOString())
        )
      );
      const chartDataPoints = createChartDataPoints(
        Date.parse(payloads[0].from),
        Date.parse(payloads[0].to),
        selectedSettings.resolution * 1000
      );
      getChartData(payloads, chartDataPoints, selectedSettings.keys);
    },
    [getChartData, sourceDropdownData]
  );

  const normalizeSourceDropdownData = useCallback((sensorGroupsList, sensorsList) => {
    const sensorGroupsDropdownData = sensorGroupsList.map((group) => ({
      id: group.id,
      name: group.name,
      type: 'group',
      capacity: group.capacity,
    }));
    const sensorsDropdownData = sensorsList
      .filter((sensor) => sensor.type === 'AREA_PRESENCE')
      .map((sensor) => ({
        id: sensor.sensorId,
        name: sensor.description || sensor.sensorId,
        type: 'sensor',
        capacity: sensor.capacity,
      }));
    setSourceDropdownData([...sensorGroupsDropdownData, ...sensorsDropdownData]);
  }, []);

  const updateSensorsList = useCallback(() => {
    return getSensors()
      .then((response) => {
        dispatch(setSensorsList(response.data));
        return response.data;
      })
      .catch((error) => {
        enqueueSnackbar(`Error getting sensors: ${error.message}`, {variant: 'error'});
      });
  }, [dispatch, enqueueSnackbar]);

  const updateSensorGroups = useCallback(() => {
    return getSensorGroups()
      .then((response) => {
        dispatch(setSensorGroupsList(response.data));
        return response.data;
      })
      .catch((error) => {
        enqueueSnackbar('Error getting sensor groups: ' + error.message, {variant: 'error'});
      });
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    // creates source drop down if source defaults (organization change)
    const fetchSources = async () => {
      const groups = await updateSensorGroups();
      const sensors = await updateSensorsList();
      normalizeSourceDropdownData(groups, sensors);
    };
    setSourceDropdownData([]);
    setChartData([]);
    fetchSources();
  }, [normalizeSourceDropdownData, updateSensorGroups, updateSensorsList, currentOrganization, dispatch]);

  useEffect(() => {
    // set default source when list of available sources updates (only on org change and first page render)
    if (sourceDropdownData.length > 0) {
      const defaultSettings = {
        ...DEFAULTDASHBOARDSETTINGS['occupancy'],
        sourceId: sourceDropdownData[0].id,
        sourceType: sourceDropdownData[0].type,
        counters: ['AVG'],
        period: moment(),
        calculateCapacity: !!sourceDropdownData[0].capacity,
      };
      dispatch(setDashboardSettings(defaultSettings));
      if (chartData.length === 0 && !isLoading) {
        setIsLoading(true);
        updateChart(defaultSettings);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDropdownData]);

  return (
    <Paper
      elevation={0}
      sx={{
        paddingTop: 3,
        paddingRight: 3,
        paddingLeft: 3,
        paddingBottom: 1,
        ...paperLayoutStyles,
      }}>
      <Grid container justifyContent="center" rowSpacing={3}>
        <Grid container item direction="row" rowSpacing={2}>
          <Grid item xs={12}>
            <SourceSelector
              dropdownData={sourceDropdownData}
              updateChart={updateChart}
              isDisabled={isLoading || sourceDropdownData.length === 0}
            />
          </Grid>
          <Grid item container xs={12} sm={4} alignSelf="flex-end" sx={{minWidth: '265px'}}>
            <ViewControls updateChart={updateChart} isDisabled={isLoading || sourceDropdownData.length === 0} />
          </Grid>
          <Grid
            container
            item
            xs={9}
            sm={true}
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            sx={{minWidth: '200px'}}>
            <PeriodArrow
              direction="back"
              updateChart={updateChart}
              isDisabled={isLoading || sourceDropdownData.length === 0}
            />
            <DateSelector updateChart={updateChart} isDisabled={isLoading || sourceDropdownData.length === 0} />
            <PeriodArrow
              direction="forward"
              updateChart={updateChart}
              isDisabled={isLoading || sourceDropdownData.length === 0}
            />
          </Grid>
          <Grid item container xs={3} sm={2} justifyContent="flex-end" sx={{minWidth: '110px'}}>
            <ResolutionSelector updateChart={updateChart} isDisabled={isLoading || sourceDropdownData.length === 0} />
          </Grid>
        </Grid>
        {isLoading ? (
          <div style={{width: '100%', textAlign: 'center'}}>
            <CircularProgress style={{margin: '130px'}} />
          </div>
        ) : chartData.length > 0 ? (
          <>
            <Legend
              sourceDropdownData={sourceDropdownData}
              colorRange={COLORRANGE}
              handleDeleteData={handleDeleteData}
              handleAddComparison={handleAddComparison}
              isDisabled={isLoading || sourceDropdownData.length === 0}
            />
            <ChartWrapper
              chartData={chartData}
              chartPeriod={[chartData[0].start, chartData[chartData.length - 1].start]}
              view={dashboardSettings.view}
              colorRange={COLORRANGE}
              keys={dashboardSettings.keys}
              dashboardType="occupancy"
            />
            <DownloadButton sourcesList={sourceDropdownData} />
          </>
        ) : (
          <Typography style={{margin: '130px'}}>No data available</Typography>
        )}
        <AdvancedSettings
          updateChart={updateChart}
          isDisabled={isLoading || sourceDropdownData.length === 0}
          sources={sourceDropdownData}
        />
      </Grid>
    </Paper>
  );
}

export default Occupancy;
