import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import StopIcon from '@mui/icons-material/Stop';
import {ListSubheader, MenuItem, Select, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import {MobileDatePicker} from '@mui/x-date-pickers';
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import moment from 'moment';
import {setDashboardCombineGraphs} from 'redux/dashboard.reducer';

import Button from 'components/Button';

const dayMarginLeft = {Mon: '13px', Tue: '48px', Wed: '40px', Thu: '32px', Fri: '25px', Sat: '20px', Sun: '10px'};

const Legend = ({sourceDropdownData, colorRange, handleDeleteData, handleAddComparison, isDisabled, reference}) => {
  const dispatch = useDispatch();
  const dashboardSettings = useSelector((state) => state.dashboard.settings);
  const [comparisonSource, setComparisonSource] = useState('');
  const [comparisonDate, setComparisonDate] = useState('');

  const prevLabel = useMemo(() => {
    switch (dashboardSettings.view) {
      case 'day':
        return 'Prev 24h';
      case 'week':
        return 'Prev 7 days';
      case 'month':
        return 'Prev 4 weeks';
      default:
        return 'Prev 365 days';
    }
  }, [dashboardSettings.view]);

  const handleRenderValue = useCallback(
    (value) => (value ? sourceDropdownData.find((dd) => dd.id === value)?.name : 'There are no sources available'),
    [sourceDropdownData]
  );

  const handleCombineGraphsChange = useCallback(() => dispatch(setDashboardCombineGraphs(false)), [dispatch]);

  const renderWeekPickerHeader = (day) => (
    <Fragment key={day + 'group'}>
      {day === 'Mon' && (
        <span key="WN" style={{paddingLeft: '25px'}}>
          WN
        </span>
      )}
      <span key={day} style={{marginLeft: dayMarginLeft[day]}}>
        {day}
      </span>
    </Fragment>
  );

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    const selectedWeek = selectedDates[0]?.week() === date.week();
    return (
      <Fragment key={pickersDayProps.key + 'group'}>
        {date.isoWeekday() === 1 && (
          <Grid
            container
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              width: '36px',
              fontSize: '0.75rem',
              fontWeight: 500,
              color: selectedWeek ? '#fff' : pickersDayProps.disabled && '#00000061',
              cursor: 'pointer',
              pointerEvents: pickersDayProps.disabled && 'none',
              borderRadius: '50%',
              ':hover': {backgroundColor: selectedWeek ? '#2c384e' : '#14316c0a'},
              backgroundColor: selectedWeek && '#405070',
              '&:focus': {
                backgroundColor: selectedWeek && '#2c384e',
              },
            }}
            onClick={(event) => pickersDayProps.onDaySelect(pickersDayProps.day)}>
            {date.week()}
          </Grid>
        )}
        <PickersDay {...pickersDayProps} disableMargin selected={false} />
      </Fragment>
    );
  };

  const dropdown = useMemo(() => {
    if (!sourceDropdownData) {
      // if comparison is not available
      return [];
    }
    const firstGroupIndex = sourceDropdownData.findIndex((source) => source.type === 'group');
    const firstSensorIndex = sourceDropdownData.findIndex((source) => source.type === 'sensor');
    return sourceDropdownData.map((source, i) => {
      const isDisabled = dashboardSettings.calculateCapacity
        ? dashboardSettings.calculateCapacity !== !!source.capacity
        : false;
      return [
        firstGroupIndex === i && (
          <ListSubheader>
            <Typography variant="overline">Sensor groups</Typography>
          </ListSubheader>
        ),
        firstSensorIndex === i && (
          <ListSubheader>
            <Typography variant="overline">Sensors</Typography>
          </ListSubheader>
        ),
        <MenuItem key={source.id} value={source.id} disabled={isDisabled}>
          {source.name + (isDisabled ? ' (no capacity set)' : '')}
        </MenuItem>,
      ];
    });
  }, [dashboardSettings.calculateCapacity, sourceDropdownData]);

  const comparisonSelector = useMemo(
    () =>
      handleAddComparison &&
      ((dashboardSettings.view === 'day' && dashboardSettings.keys.length < 31) ||
        (dashboardSettings.view === 'week' && dashboardSettings.keys.length < 10) ||
        (dashboardSettings.view === 'month' && dashboardSettings.keys.length < 12)) && (
        <MobileDatePicker
          disabled={isDisabled}
          maxDate={moment()}
          minDate={moment('01-01-2022', 'MM-DD-YYYY')}
          showDaysOutsideCurrentMonth
          showToolbar
          openTo={dashboardSettings.view === 'month' ? 'month' : 'day'}
          views={dashboardSettings.view === 'month' ? ['year', 'month'] : ['year', 'month', 'day']}
          value={comparisonDate}
          onAccept={(date) => handleAddComparison(date, comparisonSource)}
          onChange={(date) => {
            if (date === null) {
              // if user clicks OK and default date is selected but not clicked, MUI does not fire onAccept
              handleAddComparison(moment(), comparisonSource);
            } else {
              setComparisonDate(date);
            }
          }}
          dayOfWeekFormatter={dashboardSettings.view === 'week' ? renderWeekPickerHeader : null}
          renderDay={dashboardSettings.view === 'week' ? renderWeekPickerDay : null}
          ToolbarComponent={(props) => (
            <Box sx={{pr: 3, pt: 3}} display="flex" justifyContent="space-evenly">
              <Select
                variant="standard"
                aria-describedby="helper-sources"
                id="sources-select"
                disabled={isDisabled}
                value={comparisonSource}
                displayEmpty
                renderValue={handleRenderValue}
                label="Sources"
                onChange={(event) => setComparisonSource(event.target.value)}>
                {dropdown}
              </Select>
              <Button variant="text" sx={{p: 0}} onClick={() => handleAddComparison(null, comparisonSource)}>
                {prevLabel}
              </Button>
            </Box>
          )}
          clearable
          renderInput={({inputRef, inputProps, InputProps}) => (
            <Box
              sx={{
                display: 'flex',
                padding: 1,
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: '#0000000a',
                },
              }}
              ref={inputRef}
              {...inputProps}>
              <AddIcon
                fontSize="small"
                sx={{
                  textTransform: 'none',
                  borderRadius: '25%',
                }}
              />
              <Typography variant="body2" color="primary">
                Comparison
              </Typography>
              {InputProps?.endAdornment}
            </Box>
          )}
        />
      ),
    [
      comparisonDate,
      comparisonSource,
      dashboardSettings.keys.length,
      dashboardSettings.view,
      dropdown,
      handleAddComparison,
      handleRenderValue,
      isDisabled,
      prevLabel,
    ]
  );

  useEffect(() => {
    if (sourceDropdownData) {
      setComparisonSource(sourceDropdownData.length === 0 ? '' : dashboardSettings.sourceId);
    }
  }, [dashboardSettings.sourceId, sourceDropdownData]);

  return (
    <Grid item container sm={12} direction="row" alignItems="center" ref={reference}>
      {dashboardSettings.keys.map((k, i) => (
        <Tooltip
          key={k.name + k.time[0]}
          title={`${new Date(k.time[0]).toLocaleString()} - ${new Date(k.time[1]).toLocaleString()}`}>
          <Chip
            label={k.name}
            key={k.name}
            size="small"
            color="primary"
            variant={'outlined'}
            sx={{
              border: 'none',
            }}
            icon={<StopIcon style={{color: colorRange[i]}} />}
            onDelete={i === 0 || !handleDeleteData ? null : () => handleDeleteData(k.name)}
          />
        </Tooltip>
      ))}
      {dashboardSettings.combineGraphs && dashboardSettings.keys.length > 1 && dashboardSettings.view !== 'year' && (
        <Tooltip key="average-key" title="Average of all graphs">
          <Chip
            label="-- Average"
            key="average-chip-key"
            size="small"
            color="primary"
            variant={'outlined'}
            sx={{
              border: 'none',
            }}
            onDelete={handleCombineGraphsChange}
          />
        </Tooltip>
      )}
      {comparisonSelector}
    </Grid>
  );
};

export default React.memo(Legend);
