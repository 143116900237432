/* eslint-disable react/style-prop-object */
import React, {useCallback, useEffect, useState} from 'react';
import ReactMapGL, {Layer, NavigationControl, Source} from 'react-map-gl';
import {useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import {Box} from '@mui/system';

import HeatmapShape from './HeatmapShape';
import PlaybackControls from './PlaybackControls';
import PolygonShape from './PolygonShape';
import TrackShape from './TrackShape';

const navControlStyle = {
  left: 10,
  top: 10,
};

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0px',
  },
  heatmapStory: {
    borderTop: '10px solid red',
    borderBottom: '0px solid red',
    borderLeftWidth: '0',
    borderRightWidth: '0',
    borderStyle: 'solid',
    borderImage:
      'linear-gradient(to right, rgba(10,112,243), rgb(100,168,147), rgb(161,205,86), rgb(220,246,20), rgb(243,190,6), rgb(252,42,4)) 1 stretch',
  },
}));

function ReportMap(props) {
  const classes = useStyles();

  const frameRate = useSelector((state) => state.reports.frameRate);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);
  const [time, setTime] = useState(from);
  const [pause, setPause] = useState(true);
  const [speed, setSpeed] = useState(1);
  // Generic Mapbox configuration
  const [config] = useState({
    mapboxAccessToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
    mapStyle: 'mapbox://styles/daedelu/ckn4obm1q57r217qav4cyd9ud',
    attributionControl: false,
  });
  // Viewport settings
  const [viewport, setViewport] = useState({
    initialViewState: {
      bounds: [props.overlayBounds[0], props.overlayBounds[2]],
    },
    bearing: 0,
    pitch: 0,
  });

  // Viewport clamping; i.e. bounds for panning
  const setViewportClamped = (viewport) => {
    if (props.clamp) {
      if (viewport.latitude > props.maxLatitude) {
        viewport.latitude = props.maxLatitude || 0;
      }
      if (viewport.latitude < props.minLatitude) {
        viewport.latitude = props.minLatitude || 0;
      }
      if (viewport.longitude > props.maxLongitude) {
        viewport.longitude = props.maxLongitude || 0;
      }
      if (viewport.longitude < props.minLongitude) {
        viewport.longitude = props.minLongitude || 0;
      }
    }
    setViewport(viewport);
  };

  const interactionSettings = {
    dragPan: true,
    dragRotate: false,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: false,
    keyboard: true,
    doubleClickZoom: true,
    minZoom: props.minZoom || 5,
    maxZoom: props.maxZoom || 8,
    minPitch: 0,
    maxPitch: 0,

    pitchWithRotate: false,
    touchZoomRotate: false,
  };

  const polygonsFn = useCallback(() => {
    let markers;
    if (props.reportType === 'Playback') {
      const polygonsNow = props.polygons.filter((p) => p.observationTime === time);
      markers = polygonsNow.map((pts) => (
        <PolygonShape
          id={`${pts.trackId}-polygon`}
          key={`${pts.trackId}-polygon`}
          clazz={pts.class}
          coordinates={[
            [pts.x1 * props.widthFactor, (1 - pts.y1) * props.heightFactor],
            [pts.x2 * props.widthFactor, (1 - pts.y1) * props.heightFactor],
            [pts.x2 * props.widthFactor, (1 - pts.y2) * props.heightFactor],
            [pts.x1 * props.widthFactor, (1 - pts.y2) * props.heightFactor],
            [pts.x1 * props.widthFactor, (1 - pts.y1) * props.heightFactor],
          ]}
          color={`#${pts.trackId.match(/\d{6}/g)[0]}`}
          shapeType="polygon"
        />
      ));
    } else if (props.reportType === 'Tracks Diagram') {
      const tracks = [];
      props.polygons.forEach((p) => {
        if (!tracks.some((t) => t.trackId === `${p.trackId}-line`)) {
          tracks.push({
            trackId: `${p.trackId}-line`,
            color: `#${p.trackId.match(/\d{6}$/g)[0]}`,
            coordinates: [[((p.x1 + p.x2) / 2) * props.widthFactor, (1 - p.y2) * props.heightFactor]],
          });
        } else {
          const existingTrack = tracks.findIndex((t) => t.trackId === `${p.trackId}-line`);
          tracks[existingTrack].coordinates.push([
            ((p.x1 + p.x2) / 2) * props.widthFactor,
            (1 - p.y2) * props.heightFactor,
          ]);
        }
      });
      markers = tracks.map((t) => {
        return (
          <TrackShape id={t.trackId} key={t.trackId} coordinates={t.coordinates} color={t.color} shapeType="line" />
        );
      });
    } else if (props.reportType === 'Heatmap') {
      const tracks = [];
      props.polygons.forEach((p) => {
        tracks.push({
          id: `${p.trackId}-heat-${Math.random()}`,
          intensity: 0.1,
          coordinates: [((p.x1 + p.x2) / 2) * props.widthFactor, (1 - p.y2) * props.heightFactor],
        });
      });
      markers = <HeatmapShape data={tracks} />;
    }
    return markers;
  }, [props.polygons, props.heightFactor, props.reportType, props.widthFactor, time]);

  useEffect(() => {
    const tick = () => {
      if (props.reportType === 'Playback' && props.polygons.length > 0) {
        if (time < to && !pause) {
          setTime((prevState) => prevState + 100);
        } else {
          setPause(true);
        }
      }
    };
    const interval = setInterval(() => tick(), frameRate * speed);
    return () => clearInterval(interval);
  }, [frameRate, pause, props.polygons, props.reportType, speed, time, to]);

  useEffect(() => setTime(from), [from]);

  return (
    <>
      <Box sx={{width: '100%', height: '70vh'}}>
        <ReactMapGL
          style={{width: '100%', height: '100%'}}
          {...config}
          {...viewport}
          {...interactionSettings}
          onViewportChange={(viewport) => setViewportClamped(viewport)}>
          <Source id="overlay" type="image" url={props.overlayUrl} coordinates={props.overlayBounds}>
            <Layer id="overlay" source="overlay" type="raster" paint={{'raster-opacity': 1}} />
          </Source>
          {polygonsFn()}
          <NavigationControl style={navControlStyle} showCompass={false} />
        </ReactMapGL>
      </Box>
      {props.reportType === 'Heatmap' && props.polygons.length > 0 && (
        <Grid item container xs={12} className={classes.controls}>
          <div>
            <span className={classes.heatmapStory}>
              0 &nbsp;&nbsp;0.2 &nbsp;&nbsp;0.4 &nbsp;&nbsp;0.6 &nbsp;&nbsp;0.8 &nbsp;&nbsp;1
            </span>
          </div>
        </Grid>
      )}
      {props.reportType === 'Playback' && props.polygons.length > 0 && (
        <PlaybackControls
          polygons={props.polygons}
          time={time}
          startTime={from}
          speed={speed}
          pause={pause}
          setTime={setTime}
          setSpeed={setSpeed}
          setPause={setPause}
        />
      )}
    </>
  );
}

ReportMap.defaultProps = {
  // Viewport
  width: '600px',
  height: '400px',
  viewport: {
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 6,
  },

  // Clamping
  clamp: true,
  minLatitude: -90,
  maxLatitude: 90,
  minLongitude: -180,
  maxLongitude: 180,

  // Restrictions
  minZoom: 4,
  maxZoom: 8,

  mode: 'IMAGE',

  allowInteraction: true,

  overlayBounds: [
    [0, 1],
    [1, 1],
    [1, 0],
    [0, 0],
  ],

  polygons: [],
};

export default ReportMap;
