import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TextField} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import {deleteSensor, getSensorGroups, updateSensorGroup, updateSensors} from 'api/sensors';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {setSensorGroupsList} from 'redux/sensors.reducer';

import {setJcoreConfig} from '../../api/deployments';
import Button from '../../components/Button';
import FlexiblePaper from '../../components/FlexiblePaper';
import {HeaderButtonDelete} from '../../components/HeaderButtons';

import CameraConfig from './components/CameraConfig';
import DetectionClassesConfig from './components/DetectionClassesConfig';
import FlowityOutputConfig from './components/FlowityOutputConfig';
import MqttOutputConfig from './components/MqttOutputConfig';
import SpatialConfig from './components/SpatialConfig';

const CssTextField = withStyles({
  root: {
    '& textarea': {
      fontSize: '12px',
      fontFamily: 'Monospace',
    },
  },
})(TextField);

const DeploymentConfig = ({deployment, onSaveComplete}) => {
  const dispatch = useDispatch();
  const sensorGroups = useSelector((state) => state.sensors.sensorGroupsList);
  const [sensorGroupUpdates, setSensorGroupUpdates] = useState({});
  const [configString, setConfigString] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isValidJson, setIsValidJson] = useState(true);
  const [cameraId, setCameraId] = useState(deployment.cameraId || '');
  const [basedOnVersion, setBasedOnVersion] = useState(-1);
  const [editableConfig, setEditableConfig] = useState({});
  const [lastLoadedConfigVersion, setLastLoadedConfigVersion] = useState(-1);
  const [allowUpdateFromApi, setAllowUpdateFromApi] = useState(false);

  const {enqueueSnackbar} = useSnackbar();

  const uniqueVersion = deployment.id + '-' + deployment.savedConfigVersion;

  const updateSensorGroups = useCallback(() => {
    getSensorGroups()
      .then((response) => dispatch(setSensorGroupsList(response.data)))
      .catch((error) => {
        enqueueSnackbar('Error saving deployment config: ' + error.message, {variant: 'error'});
      });
  }, [dispatch, enqueueSnackbar]);

  const saveSensorGroupAssociations = useCallback(() => {
    const sensorGroupUpdatesArray = Object.keys(sensorGroupUpdates).map((sgu) => sensorGroupUpdates[sgu]);
    const sensorGroupsToUpdate = sensorGroupUpdatesArray.filter(
      (sgu) =>
        !_.isEqual(
          sgu,
          sensorGroups.find((sg) => sg.id === sgu.id)
        )
    );
    const requestArray = sensorGroupsToUpdate.map((sg) => updateSensorGroup(sg));
    Promise.all(requestArray)
      .then((response) => {
        updateSensorGroups();
      })
      .catch((error) =>
        enqueueSnackbar('Error saving sensor group associations: ' + error.message, {variant: 'error'})
      );
  }, [enqueueSnackbar, sensorGroupUpdates, sensorGroups, updateSensorGroups]);

  const saveSensorsUpdates = useCallback(
    async (areaDetects, footfalls) => {
      const sensorsToDelete = deployment.sensors
        .filter(
          (modifiedSensor) =>
            ![...areaDetects, ...footfalls].find((oldSensor) => oldSensor.id === modifiedSensor.sensorId)
        )
        .map((sensor) => sensor.sensorId);
      const normalizedAreaDetects = areaDetects.map((area) => ({
        sensorId: area.id,
        description: area.areaDescription,
        isFlow: area.isFlow,
        color: area.color,
        coordinates: area.coordinates,
        deploymentId: deployment.id,
        type: 'AREA_PRESENCE',
        capacity: area.capacity,
      }));
      const normalizedFootfalls = footfalls.map((footfall) => ({
        sensorId: footfall.id,
        description: footfall.areaDescription,
        isFlow: footfall.isFlow,
        color: footfall.color,
        coordinates: footfall.coordinates,
        deploymentId: deployment.id,
        type: 'FOOTFALL',
        capacity: footfall.capacity,
      }));
      const sensorsToUpdate = [...normalizedAreaDetects, ...normalizedFootfalls].filter(
        (newSensor) =>
          !_.isEqual(
            newSensor,
            deployment.sensors.find((existingSensor) => existingSensor.sensorId === newSensor.sensorId)
          ) && !sensorsToDelete.includes(newSensor.sensorId)
      );
      try {
        if (sensorsToUpdate.length > 0) {
          await updateSensors(sensorsToUpdate);
        }
        if (sensorsToDelete.length > 0) {
          const requestArray = sensorsToDelete.map((sd) => deleteSensor(sd));
          await Promise.all(requestArray);
        }
        enqueueSnackbar('Sensors updated successfully!', {variant: 'success'});
      } catch (error) {
        enqueueSnackbar('Error updating sensors: ' + error.message, {variant: 'error'});
      }
    },
    [deployment.id, deployment.sensors, enqueueSnackbar]
  );

  const saveJson = useCallback(() => {
    const parsedConfigString = JSON.parse(configString);
    if (!parsedConfigString.detectionModel || !parsedConfigString.detectClasses) {
      enqueueSnackbar('Cannot save deployment config: please, select classes to detect', {variant: 'error'});
      return;
    }
    if (
      parsedConfigString.functions.areaDetect.find((p) => !p.areaDescription.trim()) ||
      parsedConfigString.functions.footfalls.find((p) => !p.areaDescription.trim())
    ) {
      enqueueSnackbar('Cannot save deployment config: all sensors must have a name.', {variant: 'error'});
      return;
    }
    if (
      parsedConfigString.functions.areaDetect.find((p) => p.coordinates.length === 0) ||
      parsedConfigString.functions.footfalls.find((p) => p.coordinates.length === 0)
    ) {
      enqueueSnackbar('Cannot save deployment config: all sensors must have coordinates.', {variant: 'error'});
      return;
    }
    setIsSaving(true);
    saveSensorsUpdates(parsedConfigString.functions.areaDetect, parsedConfigString.functions.footfalls);
    setJcoreConfig(deployment.id, parsedConfigString, basedOnVersion, cameraId ? cameraId : null)
      .then(() => {
        enqueueSnackbar('Deployment config saved!', {variant: 'success'});
        if (onSaveComplete) {
          onSaveComplete();
          setAllowUpdateFromApi(true);
        }
      })
      .catch((error) => {
        enqueueSnackbar('Error saving deployment config: ' + error.message, {variant: 'error'});
      })
      .finally(() => {
        setIsSaving(false);
      });
    saveSensorGroupAssociations();
  }, [
    configString,
    saveSensorsUpdates,
    deployment.id,
    basedOnVersion,
    cameraId,
    saveSensorGroupAssociations,
    enqueueSnackbar,
    onSaveComplete,
  ]);

  const updateFromObject = useCallback(
    (obj) => {
      setEditableConfig(JSON.parse(JSON.stringify(obj)));
      setConfigString(JSON.stringify(obj, null, 2));
      setIsValidJson(true);
      setBasedOnVersion(deployment.savedConfigVersion);
    },
    [deployment.savedConfigVersion]
  );

  if (lastLoadedConfigVersion !== uniqueVersion) {
    if (lastLoadedConfigVersion === -1 || allowUpdateFromApi) {
      updateFromObject(deployment.jcoreConfig);
      setLastLoadedConfigVersion(uniqueVersion);
      setAllowUpdateFromApi(false);
    }
  }

  useEffect(() => {
    updateSensorGroups();
  }, [dispatch, enqueueSnackbar, updateSensorGroups]);

  return (
    <>
      <FlexiblePaper>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4">Deployment Configuration</Typography>
          </Grid>
          {/*---------------- Save / Reset --------------------*/}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: {xs: 'left', md: 'right'},
            }}>
            <Button
              variant="text"
              mr={2}
              onClick={() => {
                updateFromObject(deployment.jcoreConfig);
              }}>
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={isSaving && <CircularProgress color="secondary" size={18} />}
              disabled={isSaving || !isValidJson}
              onClick={saveJson}>
              Save
            </Button>
          </Grid>

          {basedOnVersion !== deployment.savedConfigVersion && (
            <Grid item xs={12}>
              <Alert severity="error">
                <b>Conflict!</b>
                <p>
                  Someone else edited the config while you were working. Please press "Reset" to overwrite your changes
                  with what is on the server.
                </p>
                <HeaderButtonDelete
                  onClick={() => {
                    updateFromObject(deployment.jcoreConfig);
                  }}>
                  Reset
                </HeaderButtonDelete>
              </Alert>
            </Grid>
          )}

          {/*---------------- Camera settings --------------------*/}
          <Grid item xs={12}>
            <CameraConfig cameraId={cameraId} setCameraId={setCameraId} />
          </Grid>

          {/*---------------- Spatial --------------------*/}
          <Grid item xs={12}>
            <SpatialConfig
              editableConfig={editableConfig}
              updateFromObject={updateFromObject}
              deploymentId={deployment.id}
              deviceId={deployment.deviceId}
              sensorGroupUpdates={sensorGroupUpdates}
              setSensorGroupUpdates={setSensorGroupUpdates}
            />
          </Grid>

          {/*---------------- Detection Classes --------------------*/}
          <Grid container item xs={12} spacing={2}>
            <DetectionClassesConfig editableConfig={editableConfig} updateFromObject={updateFromObject} />
          </Grid>

          {/*---------------- Flowity Output --------------------*/}
          <Grid item xs={12}>
            <FlowityOutputConfig editableConfig={editableConfig} updateFromObject={updateFromObject} />
          </Grid>

          {/*---------------- MQTT Output --------------------*/}
          <Grid item xs={12}>
            <MqttOutputConfig editableConfig={editableConfig} updateFromObject={updateFromObject} />
          </Grid>

          {/*---------------- Raw JSON --------------------*/}
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                {/*Based on: {basedOnVersion}, Current: {deployment.savedConfigVersion}*/}
                <Typography variant="h5">Raw JSON config</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CssTextField
                  size="small"
                  style={{fontVariant: 'monospace', fontSize: '12px', marginTop: '8px'}}
                  id="outlined-multiline-static"
                  label={'JSON'}
                  multiline
                  rows={4}
                  value={configString}
                  variant="outlined"
                  fullWidth
                  error={!isValidJson}
                  helperText={
                    isValidJson ? 'Deployment configuration as JSON' : 'Invalid JSON. The validation is very strict.'
                  }
                  onChange={(e) => {
                    try {
                      const object = JSON.parse(e.target.value);
                      if (object) {
                        updateFromObject(object);
                        setIsValidJson(true);
                      } else {
                        setConfigString(e.target.value);
                        setIsValidJson(false);
                      }
                    } catch (ex) {
                      setConfigString(e.target.value);
                      setIsValidJson(false);
                    }
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/*---------------- Save / Reset --------------------*/}
          <Grid item xs={12} align="right">
            <Button
              variant="text"
              mr={2}
              onClick={() => {
                updateFromObject(deployment.jcoreConfig);
              }}>
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={isSaving && <CircularProgress color="secondary" size={18} />}
              disabled={isSaving || !isValidJson}
              onClick={saveJson}>
              Save
            </Button>
          </Grid>
        </Grid>
      </FlexiblePaper>
    </>
  );
};

export default DeploymentConfig;
