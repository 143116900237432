import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import Paper from '@mui/material/Paper';
import {setDashboardSelectedFlow} from 'redux/dashboard.reducer';

import FlowTableRow from './FlowTableRow';

const hoverStyles = {
  '&:hover': {
    backgroundColor: '#0000000a',
    cursor: 'pointer',
  },
};

function Matrix({exits, entries, flowData, updateChart, isShowingChart}) {
  const dispatch = useDispatch();
  const sensorsList = useSelector((state) => state.sensors.sensorsList);
  const dashboardSettings = useSelector((state) => state.dashboard.settings);

  const onExitColumnSelection = useCallback(
    (exit) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.selectedFlow = {entry: null, exit, detectionClass: null};
      dispatch(setDashboardSelectedFlow({entry: null, exit, detectionClass: null}));
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );

  const isCellSelected = useCallback(
    (entry, exit, detectionClass) => {
      if (!isShowingChart) {
        // if chart is not showing
        return false;
      }
      if (dashboardSettings.selectedFlow.entry === 'Total' && dashboardSettings.selectedFlow.exit === 'Total') {
        // when Total > Total cell is selected
        return (
          (dashboardSettings.selectedFlow.entry === entry || dashboardSettings.selectedFlow.exit === exit) &&
          dashboardSettings.selectedFlow.detectionClass === detectionClass
        );
      } else if (
        dashboardSettings.selectedFlow.entry !== null &&
        (dashboardSettings.selectedFlow.exit === null || dashboardSettings.selectedFlow.exit === 'Total')
      ) {
        // when row selected entry name or total, no exit or total
        return (
          dashboardSettings.selectedFlow.entry === entry &&
          dashboardSettings.selectedFlow.detectionClass === detectionClass
        );
      } else if (
        dashboardSettings.selectedFlow.exit !== null &&
        (dashboardSettings.selectedFlow.entry === null || dashboardSettings.selectedFlow.entry === 'Total')
      ) {
        // when column selected no entry or total, exit name or total
        return (
          dashboardSettings.selectedFlow.exit === exit &&
          dashboardSettings.selectedFlow.detectionClass === detectionClass
        );
      } else {
        // when cell selected
        return (
          dashboardSettings.selectedFlow.entry === entry &&
          dashboardSettings.selectedFlow.exit === exit &&
          dashboardSettings.selectedFlow.detectionClass === detectionClass
        );
      }
    },
    [dashboardSettings, isShowingChart]
  );

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" sx={{minWidth: 650}} aria-label="flow table">
        <TableHead>
          <TableRow>
            <TableCell sx={{border: 0}}></TableCell>
            <TableCell align={'center'} sx={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>
              In
            </TableCell>
            <TableCell colSpan={exits.length} align={'center'}>
              Out
            </TableCell>
            <TableCell sx={{border: 0}}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell sx={{borderRight: '1px solid rgba(224, 224, 224, 1)'}} align={'center'}>
              Sensor name
            </TableCell>
            {exits.map((exit) => (
              <TableCell
                key={'column' + exit}
                align={'center'}
                sx={{backgroundColor: isCellSelected(null, exit, null) ? '#f0f1f4' : 'inherit', ...hoverStyles}}
                onClick={() => onExitColumnSelection(exit)}>
                {sensorsList.find((s) => s.sensorId === exit)?.description || exit}
              </TableCell>
            ))}
            <TableCell
              align="right"
              sx={{
                fontWeight: 'bold',
                ...hoverStyles,
                backgroundColor: isCellSelected(null, 'Total', null) ? '#f0f1f4' : 'inherit',
              }}
              onClick={() => onExitColumnSelection('Total')}>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => (
            <FlowTableRow
              key={'flow-row-' + entry}
              entry={entry}
              entryName={sensorsList.find((s) => s.sensorId === entry)?.description || entry}
              exits={exits}
              flowData={flowData.filter((fd) => fd.entry.sensorId === entry)}
              updateChart={updateChart}
              isCellSelected={isCellSelected}
            />
          ))}
          <FlowTableRow
            key={'flow-row-total'}
            entry="Total"
            entryName="Total"
            entryIndex={-1}
            exits={exits}
            flowData={flowData}
            updateChart={updateChart}
            isCellSelected={isCellSelected}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Matrix;
