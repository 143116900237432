import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Grid, Typography} from '@mui/material';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {curveLinear} from '@visx/curve';
import {localPoint} from '@visx/event';
import {GlyphCircle} from '@visx/glyph';
import {GridColumns, GridRows} from '@visx/grid';
import {Group} from '@visx/group';
import {scaleBand, scaleLinear} from '@visx/scale';
import {AreaClosed, Line, LinePath} from '@visx/shape';
import {defaultStyles, TooltipWithBounds, useTooltip} from '@visx/tooltip';
import _ from 'lodash';
import moment from 'moment';

import Bars from './Bars';

const getDate = (d) => +d.start;

const Chart = ({chartData, view, colorRange, keys, width, height}) => {
  const dashboardSettingsHighlights = useSelector((state) => state.dashboard.settings.highlights);
  const dashboardSettingsDays = useSelector((state) => state.dashboard.settings.showDays);
  const dashboardSettingsCombineGraphs = useSelector((state) => state.dashboard.settings.combineGraphs);
  const dashboardSettingsCalculateCapacity = useSelector((state) => state.dashboard.settings.calculateCapacity);
  const dashboardSettingsChartType = useSelector((state) => state.dashboard.settings.chartType);
  const dashboardSettingsResolution = useSelector((state) => state.dashboard.settings.resolution);
  const {tooltipData, tooltipLeft = 0, tooltipTop = 0, showTooltip, hideTooltip} = useTooltip();

  // bounds
  const margin = {top: 20, right: 20, bottom: 24, left: 40};
  const innerWidth = (width || 700) - margin.left - margin.right;
  const innerHeight = (height || 250) - margin.top - margin.bottom;

  const formatChartDate = useCallback(
    (t) => {
      const date = new Date(t);
      switch (view) {
        case 'day':
          const hour = date.getHours();
          const minute = '0' + date.getMinutes();
          return hour + ':' + minute.slice(-2);
        case 'week':
          const weekDay = date.toLocaleDateString(undefined, {weekday: 'long'});
          return weekDay;
        case 'month':
          const day = date.getDate();
          return day;
        default:
          const month = date.toLocaleDateString(undefined, {month: 'short'});
          return month;
      }
    },
    [view]
  );

  const formatTooltipDate = (t) => {
    const date = new Date(t);
    const hour = date.getHours();
    const minute = '0' + date.getMinutes();
    const day = moment(date).format('ddd, MMM D');
    return day + ' ' + hour + ':' + minute.slice(-2);
  };

  const displayData = useMemo(() => {
    if (view === 'day') {
      return chartData;
    }
    return chartData.filter((chd) => dashboardSettingsDays.some((day, i) => moment(chd.start).isoWeekday() === day));
  }, [chartData, dashboardSettingsDays, view]);

  const allTimes = useMemo(() => displayData.map((dp) => dp.start), [displayData]);
  const dateTicks = useMemo(() => {
    switch (view) {
      case 'day':
        const fullHours = allTimes.filter((date) => moment(date).isSame(moment(date).startOf('hour')));
        return fullHours
          .reverse()
          .filter((date, i) => i % 3 === 0)
          .reverse();
      case 'week':
        if (dashboardSettingsResolution === 86400) {
          // for day resolution backend sends values for utc midnight
          return allTimes.filter((date, i) => i === 0 || moment(date).isSame(moment.utc(date).startOf('day')));
        } else {
          return allTimes.filter((date) => moment(date).isSame(moment(date).startOf('day')));
        }
      case 'month':
        if (dashboardSettingsResolution === 86400) {
          // for day resolution backend sends values for utc midnight
          return allTimes.filter((date, i) => i === 0 || moment(date).isSame(moment.utc(date).startOf('day')));
        } else {
          return allTimes.filter((date) => moment(date).isSame(moment(date).startOf('day')));
        }
      default:
        const allMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        return allMonths.map((month) => allTimes.find((date) => moment(date).month() === month));
    }
  }, [view, allTimes, dashboardSettingsResolution]);

  const getValue = useCallback((d, periodName) => d[periodName], []);
  const getAvgValue = useCallback(
    (d) => {
      const nonNullKeys = keys.filter((key) => d[key.name] !== null && d[key.name] !== undefined);
      if (nonNullKeys.length > 0) {
        return nonNullKeys.reduce((sum, key) => sum + d[key.name], 0) / nonNullKeys.length;
      } else {
        return null;
      }
    },
    [keys]
  );

  const getData = useCallback((time) => displayData.find((d) => d.start === time), [displayData]);

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, innerWidth],
        domain: displayData.map(getDate),
        padding: 0,
      }),
    [displayData, innerWidth]
  );

  const yDomainMax = Math.max(
    ...displayData.map((d) => Math.max(...keys.map((k) => getValue(d, k.name)?.toFixed(3) || null)))
  );
  const yDomainMin = Math.min(
    ...displayData.map((d) => Math.min(...keys.map((k) => getValue(d, k.name)?.toFixed(3) || null)))
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [yDomainMax, yDomainMin],
        range: [0, innerHeight],
        round: true,
      }),
    [innerHeight, yDomainMax, yDomainMin]
  );

  const handleTooltip = useCallback(
    (event) => {
      const {x} = localPoint(event) || {x: 0};
      const eachBand = xScale.step();
      const indexNew = Math.floor((x - margin.left) / eachBand);
      const values = xScale.domain();
      const timestamp = values[indexNew];
      if (!timestamp) {
        return;
      }
      const dataForTimestamp = getData(timestamp);
      showTooltip({
        tooltipData: dataForTimestamp,
        tooltipLeft: x,
        tooltipTop: 0,
      });
    },
    [xScale, margin.left, getData, showTooltip]
  );

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: 'white',
    color: 'black',
    zIndex: 1000,
  };

  const chartGridRows = useMemo(
    () => (
      <GridRows
        numTicks={6}
        scale={yScale}
        width={innerWidth}
        height={innerHeight}
        stroke="#405070"
        strokeWidth={0.5}
        strokeOpacity={0.2}
        strokeDasharray="3,3"
      />
    ),
    [innerWidth, innerHeight, yScale]
  );

  const chartGridColumns = useMemo(() => {
    const lineOffset = (innerWidth / displayData.length / 2) * -1; // place lines at the start of a band
    return (
      <GridColumns
        scale={xScale}
        width={innerWidth}
        height={innerHeight}
        tickValues={dateTicks}
        offset={lineOffset}
        stroke="#405070"
        strokeWidth={0.5}
        strokeOpacity={0.2}
        strokeDasharray="3,3"
      />
    );
  }, [dateTicks, displayData.length, innerHeight, innerWidth, xScale]);

  const chartAxisLeft = useMemo(
    () => (
      <AxisLeft
        scale={yScale}
        numTicks={6}
        strokeWidth={0.5}
        tickLabelProps={() => ({
          fill: '#405070',
          fontSize: 12,
          textAnchor: 'end',
        })}
        tickFormat={(tickText) => tickText + (dashboardSettingsCalculateCapacity && '%')}
      />
    ),
    [dashboardSettingsCalculateCapacity, yScale]
  );

  const chartAxisBottom = useMemo(() => {
    const tickOffset = innerWidth / displayData.length / 2; // place ticks at the start of a band
    return (
      <AxisBottom
        scale={xScale}
        stroke={'#405070'}
        strokeWidth={0.5}
        tickFormat={formatChartDate}
        tickStroke={'#405070'}
        tickTextFill={'#405070'}
        top={innerHeight}
        tickLabelProps={() => ({
          fill: '#405070',
          fontSize: 12,
          textAnchor: 'middle',
        })}
        tickLineProps={{transform: 'translate(-' + tickOffset + ')'}}
        tickValues={dateTicks}
      />
    );
  }, [dateTicks, displayData.length, formatChartDate, innerHeight, innerWidth, xScale]);

  const higlightedDays = useMemo(() => {
    if (view === 'day') {
      return [];
    }
    return displayData.map((chd, i) => {
      let newChdValue;
      if (dashboardSettingsHighlights.some((day) => moment(chd.start).isoWeekday() === day)) {
        newChdValue = chd[keys[0].name] || 0;
      } else if (
        i !== 0 &&
        dashboardSettingsHighlights.some((day) => moment(displayData[i - 1].start).isoWeekday() === day)
      ) {
        // needed to highlight the last datapoint of the day
        newChdValue = chd[keys[0].name] || 0;
      } else {
        newChdValue = null;
      }
      return {
        ...chd,
        [keys[0].name]: newChdValue,
      };
    });
  }, [displayData, dashboardSettingsHighlights, keys, view]);

  const chartHighlights = useMemo(
    () => (
      <AreaClosed
        data={higlightedDays}
        x={(d) => xScale(getDate(d))}
        y={(d) => yScale(yDomainMax)}
        y0={innerHeight}
        yScale={yScale}
        fill={'#40507020'}
        defined={(a) => {
          return a[keys[0].name] !== null;
        }}
      />
    ),
    [higlightedDays, innerHeight, keys, xScale, yDomainMax, yScale]
  );

  const chartLines = useMemo(() => {
    const lines = [];
    if (dashboardSettingsCombineGraphs && view !== 'year' && keys.length > 1) {
      lines.push(
        <LinePath
          key="Average"
          data={displayData}
          curve={curveLinear}
          x={(d) => xScale(getDate(d))}
          y={(d) => yScale(getAvgValue(d))}
          stroke="#000000"
          strokeWidth={1}
          strokeDasharray={(3, 3)}
          defined={(d) => {
            return keys.some((key) => d[key.name] !== null);
          }}
          onMouseMove={handleTooltip}
        />
      );
    }
    keys.forEach((k, i) => {
      lines.push(
        <LinePath
          key={k.name}
          data={displayData}
          curve={curveLinear}
          x={(d) => xScale(getDate(d))}
          y={(d) => yScale(getValue(d, k.name))}
          stroke={colorRange[i]}
          strokeWidth={1}
          defined={(a) => {
            return a[k.name] !== null;
          }}
          onMouseMove={handleTooltip}
        />
      );
    });
    return lines;
  }, [
    colorRange,
    dashboardSettingsCombineGraphs,
    displayData,
    getAvgValue,
    getValue,
    handleTooltip,
    keys,
    view,
    xScale,
    yScale,
  ]);

  return (
    <>
      <svg width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          {chartGridRows}
          {chartGridColumns}
          {chartAxisLeft}
          {chartAxisBottom}
          {chartHighlights}
          {dashboardSettingsChartType === 'bar' ? (
            <Bars
              chartData={chartData}
              keys={keys}
              innerHeight={innerHeight}
              getDate={getDate}
              innerWidth={innerWidth}
              xScale={xScale}
              yScale={yScale}
            />
          ) : (
            chartLines
          )}
          {tooltipData && (
            <Line
              from={{x: tooltipLeft - margin.left, y: 0}}
              to={{x: tooltipLeft - margin.left, y: innerHeight}}
              stroke={'#405070'}
              strokeWidth={1}
              pointerEvents="none"
            />
          )}
          {tooltipData &&
            dashboardSettingsChartType === 'line' &&
            keys.map(
              (key, i) =>
                tooltipData[key.name] !== null &&
                tooltipData[key.name] !== undefined && (
                  <g key={tooltipData.start + '-' + key.name}>
                    <GlyphCircle
                      left={tooltipLeft - margin.left}
                      top={yScale(getValue(tooltipData, key.name))}
                      size={110}
                      fill={colorRange[i]}
                      stroke={'white'}
                      strokeWidth={2}
                    />
                  </g>
                )
            )}
          {/* // glyph for average graph */}
          {dashboardSettingsCombineGraphs &&
            dashboardSettingsChartType === 'line' &&
            view !== 'year' &&
            keys.length > 1 &&
            tooltipData &&
            getAvgValue(tooltipData) && (
              <g key={tooltipData.start + '-average-glyph'}>
                <GlyphCircle
                  left={tooltipLeft - margin.left}
                  top={yScale(getAvgValue(tooltipData))}
                  size={110}
                  fill="#000000"
                  stroke={'white'}
                  strokeWidth={2}
                />
              </g>
            )}
          <rect
            x={0}
            y={0}
            width={innerWidth}
            height={innerHeight}
            onTouchStart={handleTooltip}
            fill={'transparent'}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
        </Group>
      </svg>
      {/* render a tooltip */}
      {tooltipData && (
        <TooltipWithBounds key={tooltipData.start} top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <Grid container direction="column">
            <Typography variant="p">{formatTooltipDate(getDate(tooltipData))}</Typography>
            {dashboardSettingsCalculateCapacity && (
              <Typography
                variant="p"
                style={{
                  color: colorRange[0],
                }}>
                Capacity: {keys[0].source.capacity}
              </Typography>
            )}
            {keys.map((k, i) => {
              if (getValue(tooltipData, k.name) === undefined) {
                return <></>;
              }
              let countValue = 'No data';
              if (getValue(tooltipData, k.name) !== null) {
                countValue = getValue(tooltipData, k.name);
                if (dashboardSettingsCalculateCapacity) {
                  countValue = _.round(countValue, 1) + '%';
                }
              }
              const label = dashboardSettingsCalculateCapacity ? 'Occupancy' : 'Count';
              return (
                <Typography
                  variant="p"
                  key={k.name}
                  style={{
                    color: colorRange[i],
                    height: keys.length > 25 ? '11px' : 'inherit',
                    fontSize: keys.length > 25 ? '12px' : 'inherit',
                  }}>
                  {label}: {countValue}
                </Typography>
              );
            })}
            {dashboardSettingsCombineGraphs && keys.length > 1 && view !== 'year' && (
              <Typography variant="p" key="average-tooltip" style={{color: 'black', paddingTop: '2px'}}>
                Average:{' '}
                {getAvgValue(tooltipData) !== null
                  ? dashboardSettingsCalculateCapacity
                    ? _.round(getAvgValue(tooltipData), 1) + '%'
                    : getAvgValue(tooltipData)
                  : 'No data'}
              </Typography>
            )}
          </Grid>
        </TooltipWithBounds>
      )}
    </>
  );
};

export default React.memo(Chart);
