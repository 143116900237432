import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {refreshDeployments, refreshDevices} from 'helpers';
import {useSnackbar} from 'notistack';

import DebugItem from '../../components/DebugItem';
import {HeaderButtonRefresh} from '../../components/HeaderButtons';
import InformationalLoader from '../../components/Progress/InformationalLoader';
import {withAuthorization} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';

import DeviceDeploymentsCard from './components/DeviceDeploymentsCard';
import DevicePropertiesCard from './components/DevicePropertiesCard';
import DeviceStatusCard from './components/DeviceStatusCard';

function DevicePage(props) {
  const {did} = useParams('did');
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();

  const devicesErrorCode = useSelector((state) => state.devices.devicesErrorCode);

  const devicesList = useSelector((state) => state.devices.devicesList);
  const isLoadingDevices = useSelector((state) => state.devices.isLoadingDevices);

  const device = devicesList.filter((d) => d.id === did)[0];

  const isLoadingDeployments = useSelector((state) => state.deployments.isLoadingDeployments);

  const refresh = useCallback(() => {
    refreshDevices(dispatch, enqueueSnackbar);
    refreshDeployments(dispatch, enqueueSnackbar);
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    const interval = setInterval(refresh, 60000);
    refresh();
    return () => clearInterval(interval);
  }, [refresh]);

  const useMainContent = useCallback(() => {
    if (devicesErrorCode !== '') {
      return (
        <Grid item xs={12}>
          <Alert severity="error">Error loading device: {devicesErrorCode}</Alert>
        </Grid>
      );
    } else if (device) {
      return (
        <>
          {device && device.runningConfigVersion !== device.savedConfigVersion && (
            <Grid item xs={12}>
              <Alert severity="info">
                This device has pending updates to its configuration. Running version: {device.runningConfigVersion},
                new version: {device.savedConfigVersion}
              </Alert>
            </Grid>
          )}
          {/* Can be null */}
          {device.deviceConfigUpdateStatusSuccessful === false && (
            <Grid item xs={12}>
              <Alert severity="error">{device.deviceConfigUpdateStatusMessage}</Alert>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <DevicePropertiesCard device={device} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DeviceStatusCard device={device} />
          </Grid>
        </>
      );
    } else if (isLoadingDevices) {
      return (
        <Grid item xs={12}>
          <InformationalLoader message="Loading device..." />
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <Alert severity="error">Cannot load this device!</Alert>
      </Grid>
    );
  }, [device, isLoadingDevices, devicesErrorCode]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item container xs={12} md={12} alignItems="center">
          <Typography
            variant="h4"
            sx={{
              mr: 2,
            }}>
            Device details
          </Typography>
          <HeaderButtonRefresh
            variant="outlined"
            isLoading={isLoadingDeployments || isLoadingDevices}
            onClick={(event) => {
              refresh();
            }}
          />
        </Grid>
        {useMainContent()}
      </Grid>
      <DeviceDeploymentsCard device={device} />
      <DebugItem item={device} name={'Device'} />
    </>
  );
}

export default withAuthorization(Conditions.ANY)(DevicePage);
