import React, {useCallback, useEffect, useState} from 'react';
import {Trans} from '@lingui/macro';
import RefreshIcon from '@mui/icons-material/Refresh';
import {CircularProgress} from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {getPicture, getPictureStatus, takePicture} from 'api/deployments';
import {useSnackbar} from 'notistack';

import {modes} from './ConfiguratorMap';

const DEFAULTSETTINGS = {
  mode: modes.IMAGE,
  overlayUrl: '/img/floorplan.jpg',
  viewport: {
    latitude: 0.5,
    longitude: 0.5,
    zoom: 8.5,
  },
  imageLoaded: false,

  clamp: true,
  minLatitude: 0,
  maxLatitude: 1,
  minLongitude: 0,
  maxLongitude: 1,

  minZoom: 8,
  maxZoom: 10,
};

const ConfiguratorImage = ({deploymentId, imageModeSettings = DEFAULTSETTINGS, setImageModeSettings}) => {
  const [pictureStatus, setPictureStatus] = useState('UNKNOWN');

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    const update = () => {
      getPictureStatus(deploymentId).then((data) => {
        setPictureStatus(data.configPictureStatus);
      });
    };

    const t = setInterval(update, 10000);
    if (!imageModeSettings.imageLoaded) {
      update();
    }

    return () => {
      clearTimeout(t);
    };
  }, [deploymentId, imageModeSettings]);

  useEffect(() => {
    if (!imageModeSettings.imageLoaded && pictureStatus === 'HAS_IMAGE') {
      getPicture(deploymentId).then((data) => {
        const newSettings = {...imageModeSettings, overlayUrl: data.data, imageLoaded: true};
        const width = data.width;
        const height = data.height;
        if (width > height) {
          const tmp = height / width;
          newSettings.overlayBounds = [
            [0, tmp],
            [1, tmp],
            [1, 0],
            [0, 0],
          ];
          newSettings.viewport.latitude = tmp / 2;
          newSettings.heightFactor = tmp;
          newSettings.widthFactor = 1;
        } else {
          const tmp = width / height / 2;
          newSettings.overlayBounds = [
            [0, 1],
            [tmp, 1],
            [tmp, 0],
            [0, 0],
          ];
          newSettings.heightFactor = 1;
          newSettings.widthFactor = tmp;
        }

        setImageModeSettings(newSettings);
      });
    }
  }, [deploymentId, imageModeSettings, pictureStatus, setImageModeSettings]);

  const doTakePicture = useCallback(() => {
    takePicture(deploymentId)
      .then(() => {
        setImageModeSettings({...imageModeSettings, imageLoaded: false});
        setPictureStatus('PENDING');
      })
      .catch((error) => {
        enqueueSnackbar('Error saving deployment config: ' + error.message, {variant: 'error'});
      });
  }, [deploymentId, setImageModeSettings, imageModeSettings, enqueueSnackbar]);

  return (
    <Grid container>
      {pictureStatus === 'UNKNOWN' ? (
        <Grid item container xs={12} justifyContent="center">
          <CircularProgress style={{margin: '0 auto'}} />
        </Grid>
      ) : (
        <Grid item container xs={12} direction="column" spacing={1}>
          {pictureStatus === 'NO_IMAGE' && (
            <Grid item>
              <Alert severity="info">
                This device currently has no image, and you cannot change spatial configuration.
              </Alert>
            </Grid>
          )}
          {pictureStatus === 'PENDING' && (
            <Grid item>
              <Alert severity="info">
                Image request received, waiting for device. This usually takes at least 5 minutes.
                <br />
                <br />
                While a image request is in progress, you cannot change spatial configuration or request a new image.
              </Alert>
            </Grid>
          )}
          <Grid item>
            <Button
              disabled={pictureStatus === 'PENDING'}
              color="primary"
              variant="contained"
              startIcon={<RefreshIcon />}
              onClick={() => {
                doTakePicture();
              }}>
              <Trans>Request new image</Trans>
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfiguratorImage;
