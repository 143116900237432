import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, LinearProgress, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import {TimePicker} from '@mui/x-date-pickers';
import {getOrganizationSettings, patchOrganizationSettings} from 'api/organizations';
import moment from 'moment';
import {useSnackbar} from 'notistack';

import {SaveButton} from './SaveButton';

function Sensors() {
  const {enqueueSnackbar} = useSnackbar();
  const currentOrganizationId = useSelector((state) => state.session.currentOrganization.id);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [sensorResetTime, setSensorResetTime] = useState(moment());
  const [sensorResetTimeSaved, setSensorResetTimeSaved] = useState(moment());

  const fetchOrganizationSettings = useCallback(() => {
    setIsLoading(true);
    getOrganizationSettings(currentOrganizationId)
      .then((response) => {
        const dbSensorResetTime = moment
          .utc()
          .hours(response.sensorResetTime.slice(0, 2))
          .minutes(response.sensorResetTime.slice(4, 5))
          .seconds(response.sensorResetTime.slice(7, 8))
          .local();
        setSensorResetTime(dbSensorResetTime);
        setSensorResetTimeSaved(dbSensorResetTime);
      })
      .catch((error) => {
        enqueueSnackbar(`Error getting organization settings: ${error.message}`, {variant: 'error'});
      })
      .finally(() => setIsLoading(false));
  }, [currentOrganizationId, enqueueSnackbar]);

  const handleSave = useCallback(() => {
    if (!isSaving) {
      setIsSaving(true);
      patchOrganizationSettings(currentOrganizationId, {
        sensorResetTime: moment(sensorResetTime).utc().format('HH:mm:ss'),
      })
        .then((response) => setSensorResetTimeSaved(sensorResetTime))
        .catch((error) => {
          enqueueSnackbar(`Error saving organization settings: ${error.message}`, {variant: 'error'});
        })
        .finally(() => setIsSaving(false));
    }
  }, [currentOrganizationId, enqueueSnackbar, isSaving, sensorResetTime]);

  const sensorSettingsContent = useMemo(() => {
    if (isLoading) {
      return <LinearProgress />;
    } else {
      return (
        <Grid item container xs={12}>
          <Grid item container xs={12}>
            <TimePicker
              label="Sensor reset time"
              value={sensorResetTime}
              onChange={setSensorResetTime}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <SaveButton
              disabled={
                sensorResetTime.format('HH:mm:ss') === sensorResetTimeSaved.format('HH:mm:ss') ||
                !sensorResetTime.isValid() ||
                isLoading ||
                isSaving
              }
              isSaving={isSaving}
              onClick={handleSave}
            />
          </Grid>
        </Grid>
      );
    }
  }, [handleSave, isLoading, isSaving, sensorResetTime, sensorResetTimeSaved]);

  useEffect(() => {
    fetchOrganizationSettings();
  }, [fetchOrganizationSettings]);

  return (
    <Accordion defaultExpanded sx={{width: '100%'}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="sensors-content" id="sensors-header">
        <Grid container alignItems="center">
          <Typography>Sensors</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{sensorSettingsContent}</AccordionDetails>
    </Accordion>
  );
}

export default Sensors;
