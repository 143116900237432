import {uuidv4} from 'api/mock';
import moment from 'moment';

export const COUNTERS = [
  {id: 'AVG', name: 'Average'},
  {id: 'MAX', name: 'Maximum'},
  {id: 'MIN', name: 'Minimum'},
  {id: 'MED', name: 'Median'},
];

export const WEEK = [
  {id: 1, name: 'Monday'},
  {id: 2, name: 'Tuesday'},
  {id: 3, name: 'Wednesday'},
  {id: 4, name: 'Thursday'},
  {id: 5, name: 'Friday'},
  {id: 6, name: 'Saturday'},
  {id: 7, name: 'Sunday'},
];

export const VIEWS = ['day', 'week', 'month', 'year'];

export const RESOLUTIONS = {
  day: [
    {value: 60, name: '1 minute'},
    {value: 300, name: '5 minutes'},
    {value: 600, name: '10 minutes'},
    {value: 3600, name: '1 hour'},
  ],
  week: [
    {value: 600, name: '10 minutes'},
    {value: 3600, name: '1 hour'},
    {value: 86400, name: '1 day'},
  ],
  month: [
    {value: 3600, name: '1 hour'},
    {value: 86400, name: '1 day'},
  ],
  year: [
    {value: 10800, name: '3 hours'},
    {value: 86400, name: '1 day'},
    {value: 604800, name: '1 week'},
    // {id: 86400, name: '1 month'},
  ],
};

export const DEFAULTRESOLUTIONS = {
  occupancy: {
    day: {value: 300, name: '5 minutes'},
    week: {value: 600, name: '10 minutes'},
    month: {value: 3600, name: '1 hour'},
    year: {value: 10800, name: '3 hours'},
  },
  flow: {
    day: {value: 3600, name: '1 hour'},
    week: {value: 3600, name: '1 hour'},
    month: {value: 86400, name: '1 day'},
    year: {value: 86400, name: '1 day'},
  },
  counters: {
    day: {value: 3600, name: '1 hour'},
    week: {value: 3600, name: '1 hour'},
    month: {value: 86400, name: '1 day'},
    year: {value: 86400, name: '1 day'},
  },
};

export const DEFAULTDASHBOARDSETTINGS = {
  occupancy: {
    dashboardType: 'occupancy',
    sourceType: '',
    sourceId: '',
    view: 'day',
    resolution: 300,
    period: null,
    combineGraphs: false,
    counters: ['AVG'],
    keys: [],
    selectedFlow: {entry: null, exit: null, detectionClass: null},
    highlights: [],
    showDays: [1, 2, 3, 4, 5, 6, 7],
    calculateCapacity: false,
    chartType: 'line',
  },
  counters: {
    dashboardType: 'counters',
    sourceType: '',
    sourceId: '',
    view: 'day',
    resolution: 3600,
    period: null,
    combineGraphs: false,
    counters: ['IN', 'OUT'],
    keys: [],
    selectedFlow: {entry: null, exit: null, detectionClass: null},
    highlights: [],
    showDays: [1, 2, 3, 4, 5, 6, 7],
    calculateCapacity: false,
    chartType: 'bar',
  },
  flow: {
    dashboardType: 'flow',
    sourceType: '',
    sourceId: '',
    view: 'day',
    resolution: 3600,
    period: null,
    combineGraphs: false,
    counters: ['AVG'],
    keys: [],
    selectedFlow: {entry: null, exit: null, detectionClass: null},
    highlights: [],
    showDays: [1, 2, 3, 4, 5, 6, 7],
    calculateCapacity: false,
    chartType: 'bar',
  },
};

export const generatePayload = (selectedSettings, key, requestPeriod) => {
  let payload = {};
  if (key.source.type === 'sensor') {
    payload = {
      sensorIds: [key.source.id],
      aggregationWindow: selectedSettings.resolution,
      fields: selectedSettings.counters,
    };
  } else {
    // if source is a group
    payload = {
      sensorGroupId: key.source.id,
      dataIntervalSeconds: selectedSettings.resolution,
      areaAggregationMethod: selectedSettings.counters[0],
    };
  }
  payload.calculateCapacity = selectedSettings.calculateCapacity;
  payload.from = requestPeriod[0];
  payload.to = requestPeriod[1];
  return payload;
};

export const createChartDataPoints = (from, to, resolution) => {
  const numOfDatapoints = Math.floor((to - from) / resolution);
  const modulo = to % resolution;
  const lastDataPoint = to - modulo;
  const dataPointsArray = Array.from(Array(numOfDatapoints).keys()).map((num) => ({
    start: lastDataPoint - resolution * num,
  }));
  dataPointsArray.push({start: lastDataPoint + resolution}); // needed for the last data point to be visible in the chart and easy to hover on
  dataPointsArray.push({start: from});
  dataPointsArray.sort((a, b) => a.start - b.start);
  return dataPointsArray;
};

export const formatPeriodName = (date, view, comparison) => {
  if (date) {
    switch (view) {
      case 'day':
        return moment(date).format('ddd, MMM D');
      case 'week':
        return moment(date).format("[Week] ww [']YY");
      case 'month':
        return moment(date).format("MMMM [']YY");
      default:
        return moment(date).format('YYYY');
    }
  } else {
    const modifier = comparison ? 'Prev' : 'Last';
    switch (view) {
      case 'day':
        return modifier + ' 24h';
      case 'week':
        return modifier + ' 7 days';
      case 'month':
        return modifier + ' 4 weeks';
      default:
        return modifier + ' 365 days';
    }
  }
};

export const formatMainRequestPeriod = (selectedDate, view) => {
  let from;
  let to;
  if (!selectedDate) {
    // Last 24h or Last 7 days
    selectedDate = moment();
    switch (view) {
      case 'day':
        from = moment(selectedDate).subtract(1, 'days').toISOString();
        to = moment(selectedDate).toISOString();
        break;
      case 'week':
        from = moment(selectedDate).subtract(7, 'days').toISOString();
        to = moment(selectedDate).toISOString();
        break;
      case 'month':
        from = moment(selectedDate).subtract(28, 'days').toISOString();
        to = moment(selectedDate).toISOString();
        break;
      default:
        from = moment(selectedDate).subtract(365, 'days').toISOString();
        to = moment(selectedDate).toISOString();
    }
  } else {
    // Specific date is selected
    from = moment(selectedDate).startOf(view).toISOString();
    to = moment(selectedDate).endOf(view).toISOString();
  }
  return [from, to];
};

export const formatComparisonRequestPeriod = (selectedDate, view, mainPeriod, mainIsLatestPeriod) => {
  let from;
  let to;
  if (!selectedDate) {
    switch (view) {
      case 'day':
        // Prev 24h
        from = moment(mainPeriod[0]).subtract(1, 'days').toISOString();
        to = moment(mainPeriod[1]).subtract(1, 'days').toISOString();
        break;
      case 'week':
        // Prev 7 days
        from = moment(mainPeriod[0]).subtract(7, 'days').toISOString();
        to = moment(mainPeriod[1]).subtract(7, 'days').toISOString();
        break;
      default:
        // Prev 4 weeks
        from = moment(mainPeriod[0]).subtract(28, 'days').toISOString();
        to = moment(mainPeriod[1]).subtract(28, 'days').toISOString();
    }
  } else {
    // Specific date is selected
    const selctedDateClone = moment(selectedDate);
    // Match h, m, s and ms to main period
    const mainPeriodFrom = moment(mainPeriod[0]);
    selctedDateClone.set({
      hour: mainPeriodFrom.hours(),
      minute: mainPeriodFrom.minutes(),
      second: mainPeriodFrom.seconds(),
      millisecond: mainPeriodFrom.milliseconds(),
    });

    switch (view) {
      case 'day':
        from = selctedDateClone.toISOString();
        to = selctedDateClone.clone().add(1, 'days').toISOString();
        break;
      case 'week':
        if (!mainIsLatestPeriod) {
          // if comparing against last 7 days
          const mainPeriodToWeekday = moment(mainPeriod[0]).day();
          const newDate = selctedDateClone.clone().day(mainPeriodToWeekday);
          const endOfSelectedWeek = newDate.isBefore(selctedDateClone)
            ? selctedDateClone.clone().day(7 + mainPeriodToWeekday)
            : newDate;
          to = endOfSelectedWeek.toISOString();
          from = endOfSelectedWeek.clone().subtract(7, 'days').toISOString();
        } else {
          to = selctedDateClone.clone().endOf('week').toISOString();
          from = selctedDateClone.clone().startOf('week').toISOString();
        }
        break;
      default:
        // month
        if (!mainIsLatestPeriod) {
          // if comparing against last 4 weeks
          const mainPeriodToMonthday = moment(mainPeriod[0]).date();
          const newDate = selctedDateClone.clone().date(mainPeriodToMonthday);
          const endOfSelectedMonth = newDate.isBefore(selctedDateClone)
            ? selctedDateClone.clone().date(31 + mainPeriodToMonthday)
            : newDate;
          to = endOfSelectedMonth.toISOString();
          from = endOfSelectedMonth.clone().subtract(28, 'days').toISOString();
        } else {
          to = selctedDateClone.clone().endOf('month').toISOString();
          from = selctedDateClone.clone().startOf('month').toISOString();
        }
    }
  }
  return [from, to];
};

export const getSensor = (s) => ({name: s.id, id: uuidv4()});

export const getSensorName = (s) => s.name;

export const paperLayoutStyles = {
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
};
