import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import Paper from '@mui/material/Paper';
import {setDashboardSource} from 'redux/dashboard.reducer';

const hoverStyles = {
  '&:hover': {
    backgroundColor: '#0000000a',
    cursor: 'pointer',
  },
};

function SensorTable({isShowingChart, sensorsTableData, updateChart}) {
  const dispatch = useDispatch();
  const sensorsList = useSelector((state) => state.sensors.sensorsList).filter((s) => s.type === 'FOOTFALL');
  const dashboardSettings = useSelector((state) => state.dashboard.settings);

  const handleSourceChange = useCallback(
    (value, counter) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.sourceId = value;
      dashboardSettingsClone.counters = [counter];
      dispatch(setDashboardSource({sourceId: value, sourceType: '', counters: [counter]}));
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table size="small" sx={{minWidth: 650}} aria-label="flow table">
          <TableHead>
            <TableRow>
              <TableCell sx={{borderRight: '1px solid rgba(224, 224, 224, 1)', maxWidth: '70px'}}>
                Sensor name
              </TableCell>
              <TableCell sx={{borderRight: '1px solid rgba(224, 224, 224, 1)'}} align={'center'}>
                IN
              </TableCell>
              <TableCell sx={{borderRight: '1px solid rgba(224, 224, 224, 1)'}} align={'center'}>
                OUT
              </TableCell>
              <TableCell align={'center'}>NET</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sensorsList
              .sort((a, b) => a.description.localeCompare(b.description))
              .map((s) => {
                const inCounter = sensorsTableData
                  .filter((d) => d.sensorId === s.sensorId && d.field === 'IN')
                  .reduce((partialSum, data) => partialSum + data.value, 0);
                const outCounter = sensorsTableData
                  .filter((d) => d.sensorId === s.sensorId && d.field === 'OUT')
                  .reduce((partialSum, data) => partialSum + data.value, 0);
                return (
                  <TableRow key={s.sensorId + s.deviceId} value={s.sensorId}>
                    <TableCell
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        width: '1%',
                        whiteSpace: 'nowrap',
                      }}>
                      {s.description || s.sensorId}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSourceChange(s.sensorId, 'IN')}
                      sx={
                        isShowingChart &&
                        dashboardSettings.sourceId === s.sensorId &&
                        dashboardSettings.counters[0] === 'IN'
                          ? {
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              backgroundColor: '#f0f1f4',
                              ...hoverStyles,
                            }
                          : {borderRight: '1px solid rgba(224, 224, 224, 1)', ...hoverStyles}
                      }
                      align={'right'}>
                      {inCounter}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSourceChange(s.sensorId, 'OUT')}
                      sx={
                        isShowingChart &&
                        dashboardSettings.sourceId === s.sensorId &&
                        dashboardSettings.counters[0] === 'OUT'
                          ? {
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              backgroundColor: '#f0f1f4',
                              ...hoverStyles,
                            }
                          : {borderRight: '1px solid rgba(224, 224, 224, 1)', ...hoverStyles}
                      }
                      align={'right'}>
                      {outCounter}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSourceChange(s.sensorId, 'NET')}
                      sx={
                        isShowingChart &&
                        dashboardSettings.sourceId === s.sensorId &&
                        dashboardSettings.counters[0] === 'NET'
                          ? {
                              backgroundColor: '#f0f1f4',
                              ...hoverStyles,
                            }
                          : {...hoverStyles}
                      }
                      align={'right'}>
                      {inCounter - outCounter}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default SensorTable;
