import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/ArrowDownward';
import {Button, CircularProgress} from '@mui/material';
import Grid from '@mui/material/Grid';
import {getCSV, getCSVForGroup} from 'api/measurements';
import {localDownload} from 'helpers';
import {useSnackbar} from 'notistack';

import {generatePayload} from '../helpers';

const DownloadButton = ({sourcesList}) => {
  const {enqueueSnackbar} = useSnackbar();

  const dashboardSettings = useSelector((state) => state.dashboard.settings);
  const keys = useSelector((state) => state.dashboard.settings.keys);

  const [isLoading, setIsLoading] = useState(false);

  const getCSVData = useCallback(
    (payloads) => {
      setIsLoading(true);
      const requestArray = payloads.map((p) => (p.aggregationWindow ? getCSV(p) : getCSVForGroup(p)));
      Promise.all(requestArray)
        .then((data) => {
          const normilizedData = data.map((datum, i) => {
            // trim whitespaces
            const trimmedDatum = datum.trim();
            // split data in rows
            let splitDatum = trimmedDatum.split('\n');
            // remove header
            splitDatum.shift();
            // remove device id from sensor entries
            if (payloads[i].sensorIds) {
              splitDatum = splitDatum.map((line) => line.slice(0, line.lastIndexOf(',')));
            }
            // add source ID
            const datumWithSourceId = splitDatum.map((line) => {
              const sourceId = payloads[i].sensorGroupId || payloads[i].sensorIds[0];
              const sourceName = sourcesList.find((source) => source.id === sourceId).name;
              return '\n' + sourceId + ',' + sourceName + ',' + line;
            });
            // join lines
            const joinedDatum = datumWithSourceId.join('');
            return joinedDatum;
          });
          // add header
          const joinedData = normilizedData.join('');
          // join array
          const dataWithHeader = 'SourceId, SourceName, Timestamp, Value' + joinedData;
          const fileName = 'flowity-' + new Date().toISOString() + '.csv';
          localDownload(dataWithHeader, fileName);
        })
        .catch((error) => enqueueSnackbar(`Error getting CSV: ${error.message}`, {variant: 'error'}))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar, sourcesList]
  );

  const handleDownloadCSV = useCallback(() => {
    const payloads = keys.map((k) =>
      generatePayload(
        dashboardSettings,
        k,
        k.time.map((t) => new Date(t).toISOString())
      )
    );
    getCSVData(payloads);
  }, [dashboardSettings, getCSVData, keys]);

  return (
    <Grid item container xs={12} justifyContent="flex-end">
      <Button
        startIcon={isLoading ? <CircularProgress size={18} /> : <FileDownloadIcon />}
        disabled={isLoading}
        variant="contained"
        size="small"
        onClick={handleDownloadCSV}>
        Download CSV
      </Button>
    </Grid>
  );
};

export default React.memo(DownloadButton);
