import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CircularProgress} from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {getJSONForSensor} from 'api/measurements';
import {getSensors} from 'api/sensors';
import _ from 'lodash';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {setDashboardKeys, setDashboardSettings} from 'redux/dashboard.reducer';
import {setSensorsList} from 'redux/sensors.reducer';

import ChartTypeSelector from './components/ChartTypeSelector';
import ChartWrapper from './components/ChartWrapper';
import DateSelector from './components/DateSelector';
import Legend from './components/Legend';
import PeriodArrow from './components/PeriodArrow';
import ResolutionSelector from './components/ResolutionSelector';
import SensorTable from './components/SensorTable';
import ViewControls from './components/ViewControls';
import {
  createChartDataPoints,
  DEFAULTDASHBOARDSETTINGS,
  formatMainRequestPeriod,
  formatPeriodName,
  paperLayoutStyles,
} from './helpers';

const COLORRANGE = ['#3b89bf', '#ee8c2b'];

function Counters(props) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const scrollRef = useRef(null);
  const sensorsList = useSelector((state) => state.sensors.sensorsList)?.filter((s) => s.type === 'FOOTFALL');
  const dashboardSettings = useSelector((state) => state.dashboard.settings);
  const [isLoadingChartData, setIsLoadingChartData] = useState(false);
  const [isLoadingSensorsTable, setIsLoadingSensorsTable] = useState(false);
  const [isShowingChart, setIsShowingChart] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [sensorsTableData, setSensorsTableData] = useState([]);

  const getValue = useCallback((dataPoint, counter) => {
    if (counter === 'NET') {
      return dataPoint.find((dp) => dp.field === 'IN').value - dataPoint.find((dp) => dp.field === 'OUT').value;
    } else {
      return dataPoint[0].value;
    }
  }, []);

  const normilizeDataForChart = useCallback(
    (data, chartDataPoints, periodFrom, periodName, counter) => {
      const dataWithEpochTime = data.map((d) => ({...d, start: Date.parse(d.start)}));
      const dataGroupedByTime = _(dataWithEpochTime).groupBy('start').value();
      const populatedDataPoints = chartDataPoints.map((chdp) => {
        const dataPoint = dataGroupedByTime[chdp.start + (Date.parse(periodFrom) - chartDataPoints[0].start)];
        return {
          ...chdp,
          [periodName + ' (' + counter + ')']: dataPoint ? getValue(dataPoint, counter) : null,
        };
      });
      const sortedData = populatedDataPoints.sort((a, b) => a.start - b.start);
      return sortedData;
    },
    [getValue]
  );

  const getChartData = useCallback(
    (payload, chartDataPoints, periodName, counters) => {
      setIsLoadingChartData(true);
      getJSONForSensor(payload)
        .then((data) => {
          const normilizedData = normilizeDataForChart(data, chartDataPoints, payload.from, periodName, counters[0]);
          return normilizedData;
        })
        .then((data) => {
          dispatch(
            setDashboardKeys([
              {name: periodName + ' (' + counters[0] + ')', time: [Date.parse(payload.from), Date.parse(payload.to)]},
            ])
          );
          setChartData(data);
          setIsShowingChart(true);
        })
        .catch((error) => enqueueSnackbar(`Error getting data: ${error.message}`, {variant: 'error'}))
        .finally(() => setIsLoadingChartData(false));
    },
    [dispatch, enqueueSnackbar, normilizeDataForChart]
  );

  const updateChart = useCallback(
    (selectedSettings) => {
      const periodName = formatPeriodName(selectedSettings.period, selectedSettings.view, false);
      const periodTimeframe = formatMainRequestPeriod(selectedSettings.period, selectedSettings.view);
      const chartDataPoints = createChartDataPoints(
        Date.parse(periodTimeframe[0]),
        Date.parse(periodTimeframe[1]),
        selectedSettings.resolution * 1000
      );
      const payload = {
        sensorIds: [selectedSettings.sourceId],
        aggregationWindow: selectedSettings.resolution,
        fields: selectedSettings.counters[0] === 'NET' ? ['IN', 'OUT'] : selectedSettings.counters,
        from: periodTimeframe[0],
        to: periodTimeframe[1],
      };
      getChartData(payload, chartDataPoints, periodName, selectedSettings.counters);
    },
    [getChartData]
  );

  const getSensorsData = useCallback(
    (payload) => {
      setIsLoadingSensorsTable(true);
      return getJSONForSensor(payload)
        .then((data) => {
          setSensorsTableData(data);
          return data;
        })
        .catch((error) => {
          enqueueSnackbar(`Error getting data: ${error.message}`, {variant: 'error'});
        })
        .finally(() => setIsLoadingSensorsTable(false));
    },
    [enqueueSnackbar]
  );

  const updateData = useCallback(
    (selectedSettings) => {
      // changes in period and view trigger table update or table + chart if chart is showing
      // resolution triggers only chart update
      const requestPeriod = formatMainRequestPeriod(selectedSettings.period, selectedSettings.view);
      const tablePayload = {
        aggregationWindow: selectedSettings.resolution,
        from: requestPeriod[0],
        to: requestPeriod[1],
        fields: ['IN', 'OUT'],
      };
      getSensorsData(tablePayload).then((data) => {
        if (isShowingChart) {
          let filteredData;
          if (selectedSettings.counters[0] === 'NET') {
            filteredData = data.filter((d) => d.sensorId === selectedSettings.sourceId);
          } else {
            filteredData = data.filter(
              (d) => d.field === selectedSettings.counters[0] && d.sensorId === selectedSettings.sourceId
            );
          }
          const periodName = formatPeriodName(selectedSettings.period, selectedSettings.view, false);
          const chartDataPoints = createChartDataPoints(
            Date.parse(requestPeriod[0]),
            Date.parse(requestPeriod[1]),
            selectedSettings.resolution * 1000
          );
          const normilizedData = normilizeDataForChart(
            filteredData,
            chartDataPoints,
            tablePayload.from,
            periodName,
            selectedSettings.counters[0]
          );
          dispatch(
            setDashboardKeys([
              {
                name: periodName + ' (' + selectedSettings.counters[0] + ')',
                time: [Date.parse(tablePayload.from), Date.parse(tablePayload.to)],
              },
            ])
          );
          setChartData(normilizedData);
        }
      });
    },
    [dispatch, getSensorsData, isShowingChart, normilizeDataForChart]
  );

  const updateSensorsList = useCallback(() => {
    return getSensors()
      .then((response) => {
        dispatch(setSensorsList(response.data));
        return response.data;
      })
      .catch((error) => {
        enqueueSnackbar(`Error getting sensors: ${error.message}`, {variant: 'error'});
      });
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    // update sensors list on first load
    updateSensorsList();
  }, [updateSensorsList]);

  useEffect(
    () => {
      if (sensorsList.length > 0 && !isLoadingSensorsTable && sensorsTableData.length === 0) {
        // set default dashboard settings on first load
        DEFAULTDASHBOARDSETTINGS['counters'].period = moment().subtract(1, 'days');
        dispatch(setDashboardSettings(DEFAULTDASHBOARDSETTINGS['counters']));
        // get sensor table data when sensor list updates
        const requestPeriod = formatMainRequestPeriod(
          DEFAULTDASHBOARDSETTINGS['counters'].period,
          DEFAULTDASHBOARDSETTINGS['counters'].view
        );
        getSensorsData({
          // resolution does not effect results of In and Out sensors, but it matters for the chart
          aggregationWindow: DEFAULTDASHBOARDSETTINGS['counters'].resolution,
          from: requestPeriod[0],
          to: requestPeriod[1],
          // counters dashboard uses only in and out fields at the moment
          fields: ['IN', 'OUT'],
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sensorsList.length]
  );

  useEffect(() => {
    if (chartData.length > 0) {
      scrollRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [chartData]);

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        ...paperLayoutStyles,
      }}>
      <Grid container justifyContent="center" rowSpacing={3}>
        <Grid container item direction="row" rowSpacing={3}>
          <Grid item container xs={6} sm={4} alignSelf="flex-end" sx={{minWidth: '265px'}}>
            <ViewControls
              updateChart={updateData}
              isDisabled={isLoadingSensorsTable || isLoadingChartData || sensorsList.length === 0}
            />
          </Grid>
          <Grid container item direction="row" justifyContent="center" xs={6} sm={true} sx={{minWidth: '200px'}}>
            <PeriodArrow
              direction="back"
              updateChart={updateData}
              isDisabled={isLoadingSensorsTable || isLoadingChartData || sensorsList.length === 0}
            />
            <DateSelector
              updateChart={updateData}
              isDisabled={isLoadingSensorsTable || isLoadingChartData || sensorsList.length === 0}
            />
            <PeriodArrow
              direction="forward"
              updateChart={updateData}
              isDisabled={isLoadingSensorsTable || isLoadingChartData || sensorsList.length === 0}
            />
          </Grid>
        </Grid>
        <Grid container item direction="row" justifyContent="center">
          {isLoadingSensorsTable ? (
            <CircularProgress style={{margin: '130px'}} />
          ) : (
            <SensorTable
              isShowingChart={isShowingChart}
              sensorsTableData={sensorsTableData}
              updateChart={updateChart}
            />
          )}
        </Grid>
        {isLoadingChartData || (isShowingChart && isLoadingSensorsTable) ? (
          <CircularProgress style={{margin: '130px'}} />
        ) : (
          chartData.length > 0 && (
            <>
              <Grid item container justifyContent="flex-end">
                <ChartTypeSelector
                  isDisabled={isLoadingSensorsTable || isLoadingChartData || sensorsList.length === 0}
                />
                <ResolutionSelector
                  updateChart={updateChart}
                  isDisabled={isLoadingSensorsTable || isLoadingChartData || sensorsList.length === 0}
                />
              </Grid>
              <Legend
                keys={dashboardSettings.keys}
                colorRange={COLORRANGE}
                view={dashboardSettings.view}
                disabled={sensorsList.length === 0}
                reference={scrollRef}
              />
              <ChartWrapper
                chartData={chartData}
                chartPeriod={[chartData[0].start, chartData[chartData.length - 1].start]}
                view={dashboardSettings.view}
                colorRange={COLORRANGE}
                keys={dashboardSettings.keys}
                dashboardType="Counters"
              />
            </>
          )
        )}
      </Grid>
    </Paper>
  );
}

export default Counters;
