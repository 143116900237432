import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import {deleteDashboardKeys, setDashboardView} from 'redux/dashboard.reducer';

import {DEFAULTRESOLUTIONS, VIEWS} from '../helpers';

const ViewControls = ({updateChart, isDisabled}) => {
  const dispatch = useDispatch();
  const dashboardSettings = useSelector((state) => state.dashboard.settings);

  const handleViewChange = useCallback(
    (view) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.view = view;
      dashboardSettingsClone.resolution = DEFAULTRESOLUTIONS[dashboardSettings.dashboardType][view].value;
      dashboardSettingsClone.keys = [];
      const defaultPeriod =
        dashboardSettings.dashboardType === 'occupancy'
          ? moment()
          : view !== 'year'
          ? moment().subtract(1, view)
          : null;
      dashboardSettingsClone.period = defaultPeriod;
      dispatch(
        setDashboardView({
          view,
          resolution: DEFAULTRESOLUTIONS[dashboardSettings.dashboardType][view].value,
          period: defaultPeriod,
        })
      );
      dispatch(deleteDashboardKeys());
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );

  const chipList = useMemo(
    () =>
      VIEWS.map((v) => (
        <Chip
          key={v}
          label={'1 ' + v}
          size="small"
          color="primary"
          sx={{
            marginRight: 1,
          }}
          disabled={isDisabled}
          variant={dashboardSettings.view === v ? 'filled' : 'outlined'}
          onClick={() => handleViewChange(v)}
        />
      )),
    [isDisabled, dashboardSettings.view, handleViewChange]
  );
  return (
    <Grid container item direction="row">
      {chipList}
    </Grid>
  );
};

export default React.memo(ViewControls);
